<input matInput
    #searchInput
    [matAutocomplete]="auto"
    [formControl]="searchFormControl"
    [formlyAttributes]="field"
    [placeholder]="props.placeholder || ''"
    [matChipInputFor]="chipGrid"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)"
    [errorStateMatcher]="errorStateMatcher" />
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let value of filter | async" [value]="value?.value">
        {{ value?.label }}
    </mat-option>
</mat-autocomplete>
<mat-chip-grid #chipGrid aria-label="Options selected">
    <mat-chip-row *ngFor="let value of formControl.value" (removed)="remove(value)">
        {{displayValue(value)}}
        <button matChipRemove [attr.aria-label]="'remove ' + value">
            <mat-icon>cancel</mat-icon>
        </button>
    </mat-chip-row>
</mat-chip-grid>