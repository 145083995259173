import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestTableOptions, ResponseTableItems } from '../components/dynamic-table';
import gql from 'graphql-tag';
import { map, catchError } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { omitBy, isEqual } from 'lodash';
import { ApolloErrorHandlerService, HandleError, ApolloErrorHandlerType } from './apollo-error-handler.service';

export interface APIResponse {
  success: boolean;
}
export interface APIListResponse extends APIResponse, ResponseTableItems { }

export interface APIItemResponse extends APIResponse {
  item?: any;
}

export interface Model {
  name: string;
  pluralName?: string;
  fields: string;
}


export interface ListResponse<T> {
  [key: string]: {
    nodes: T[];
    totalCount: number;
  };
}

export interface CreateResponse<T> {
  [key: string]: T;
}

export interface GetResponse<T> {
  [key: string]: T;
}

export interface UpdateResponse<T> {
  [key: string]: T;
}

export interface RemoveResponse<T> {
  [key: string]: T;
}

export interface AddSubDocResponse<T> {
  [key: string]: {
    record: T;
  };
}

export interface UpdateSubDocResponse<T> {
  [key: string]: {
    record: T;
  };
}

export interface RemoveSubDocResponse<T> {
  [key: string]: {
    record: T;
  };
}

export function reduceFieldsArray(recordFields: string[]): string {
  return recordFields.reduce((p, c, i) => (p + c + ','), '');
}

export function firstToUpperCase(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function objectDiff(original: any, updated: any): any {
  return omitBy(updated, (v, k) => {
    if (typeof v === "object" && typeof original[k] === "object") {
      return isEqual(v, original[k]);
    }
    return original[k] === v;
  });
}

export interface QueryOptions {
  sort?: string;
  order?: string;
  filter?: { [key: string]: any } | null;
  page: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private handleError: HandleError;

  constructor(
    private apollo: Apollo,
    private apolloErrorHandlerService: ApolloErrorHandlerService,
  ) {
    this.handleError = this.apolloErrorHandlerService.createHandleError('AdminService');
  }

  getTableSourceFunction<T>(model: Model, searchFields?: string | string[]): (tableParams: RequestTableOptions) => Observable<ResponseTableItems> {
    const modelCap = firstToUpperCase(model.name);
    return (tableParams: RequestTableOptions) => {
      const variables: any = {
        paging: {
          limit: tableParams.pageSize,
          offset: tableParams.page * tableParams.pageSize,
        }
      };
      let filter: { [key: string]: any } | null = null;
      if (tableParams.filter && tableParams.filter.length) {
        filter = {};
        tableParams.filter.forEach((f) => {
          if (!filter) {
            return;
          }
          filter[f.field] = {
            eq: f.value
          };
        });
        variables.filter = filter;
      }
      let sort: string | null = null;
      if (tableParams.sort) {
        sort = `${tableParams.sort}_${tableParams.order ? tableParams.order : 'ASC'}`;
        variables.sort = sort;
      }
      const query = tableParams.query;
      if (query && searchFields) {
        const fields = Array.isArray(searchFields) ? searchFields : [searchFields];
        const final: any[] = [];
        fields.forEach(f => {
          const obj: any = {};
          obj[f] = { iLike: `${query}` };
          final.push(obj);
        });
        if (!filter) {
          filter = {};
        }
        filter['or'] = filter['or'] ? [...filter['or'], ...final] : final;
        variables.filter = filter;
      }
      return this.apollo.query<ListResponse<T>>({
        query: gql`
        query ItemsList($paging: OffsetPaging${!!filter ? `, $filter: ${modelCap}Filter` : ''}${!!sort ? `,$sort: SortFindMany${modelCap}Input` : ''}) {
          ${model.pluralName ? model.pluralName : `${model.name}s`}(paging: $paging${!!filter ? ', filter: $filter' : ''}${!!sort ? ', sort: $sort' : ''}) {
            nodes{
              ${model.fields}
            },
            totalCount
          }
        }`,
        variables,
      }).pipe(
        map(({ data }) => {
          const page = data[`${model.pluralName ? model.pluralName : `${model.name}s`}`];
          return { items: page.nodes, totalCount: page.totalCount };
        }),
        catchError(this.handleError<ResponseTableItems>(
          'getTableSourceFunction', { items: [], totalCount: 0 }, ApolloErrorHandlerType.DISCREET
        )),
      );
    };
  }

  query<T>(model: Model, queryOptions: QueryOptions): Observable<ResponseTableItems> {
    const modelCap = firstToUpperCase(model.name);
    const variables: any = {
      paging: {
        limit: queryOptions.pageSize,
        offset: queryOptions.page * queryOptions.pageSize,
      },
    };
    if (queryOptions.filter) {
      variables.filter = queryOptions.filter;
    }
    let sort: string | null = null;
    if (queryOptions.sort) {
      sort = `${queryOptions.sort}_${queryOptions.order ? queryOptions.order : 'ASC'}`;
      variables.sort = sort;
    }
    return this.apollo.query<ListResponse<T>>({
      query: gql`
      query ItemsList($paging: OffsetPaging${!!queryOptions.filter ? `, $filter: ${modelCap}Filter` : ''}${!!sort ? `,$sort: SortFindMany${modelCap}Input` : ''}) {
        ${model.pluralName ? model.pluralName : `${model.name}s`}(paging: $paging${!!queryOptions.filter ? ', filter: $filter' : ''}${!!sort ? ', sort: $sort' : ''}) {
          nodes{
            ${model.fields}
          },
          totalCount
        }
      }`,
      variables,
    }).pipe(
      map(({ data }) => {
        const page = data[`${model.pluralName ? model.pluralName : `${model.name}s`}`];
        return { items: page.nodes, totalCount: page.totalCount };
      }),
      catchError(this.handleError<ResponseTableItems>(
        'query', { items: [], totalCount: 0 }, ApolloErrorHandlerType.DISCREET
      )),
    );
  }

  addItem<T>(model: Model, data: T): Observable<T | undefined> {
    const modelUppercaseFirst = firstToUpperCase(model.name);
    return this.apollo.mutate<CreateResponse<T>>({
      mutation: gql`
        mutation CreateItem($input: CreateOne${modelUppercaseFirst}Input!) {
          createOne${modelUppercaseFirst}(input: $input ) {
            ${model.fields}
          }
        }`,
      variables: { input: { [model.name]: data } },
    }).pipe(
      map(({ data }) => {
        return data ? data[`createOne${modelUppercaseFirst}`] : undefined;
      }),
      catchError(this.handleError<undefined>(
        'addItem', undefined, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  getItem<T>(model: Model, itemId: string): Observable<T | undefined> {
    return this.apollo.query<GetResponse<T>>({
      query: gql`
        query GetItem($id: ID!) {
          ${model.name}(id: $id ) {
            ${model.fields}
          }
        }`,
      variables: { id: itemId },
    }).pipe(
      map(({ data }) => {
        return data[`${model.name}`];
      }),
      catchError(this.handleError<undefined>(
        'getItem', undefined, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  getItemPopulated<T>(model: Model, itemId: string): Observable<T | undefined> {
    return this.apollo.query<GetResponse<T>>({
      query: gql`
        query GetItem($_id: MongoID!) {
          ${model.name}Populated(_id: $_id ) {
            ${model.fields}
          }
        }`,
      variables: { _id: itemId },
    }).pipe(
      map(({ data }) => {
        return data[`${model.name}Populated`];
      }),
      catchError(this.handleError<undefined>(
        'getItem', undefined, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  updateItem(model: Model, itemId: string, data: any): Observable<boolean> {
    const modelUppercaseFirst = firstToUpperCase(model.name);
    const mutation = `
        mutation UpdateItem($input: UpdateOne${modelUppercaseFirst}Input!) {
          updateOne${modelUppercaseFirst}(input: $input) {
            ${model.fields}
          }
        }`;
    return this.apollo.mutate<UpdateResponse<any>>({
      mutation: gql`${mutation}`,
      variables: { input: { id: itemId, update: data } },
    }).pipe(
      map(({ data }) => {
        return data ? !!data[`updateOne${modelUppercaseFirst}`] : false;
      }),
      catchError(this.handleError<boolean>(
        'updateItem', false, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  deleteItem(model: Model, itemId: string): Observable<boolean> {
    const modelUppercaseFirst = firstToUpperCase(model.name);
    return this.apollo.mutate<RemoveResponse<any>>({
      mutation: gql`
        mutation RemoveItem($input: DeleteOne${modelUppercaseFirst}Input!) {
          deleteOne${modelUppercaseFirst}(input: $input ) {
            ${model.fields}
          }
        }`,
      variables: { input: { id: itemId } },
    }).pipe(
      map(({ data }) => {
        return data ? !!data[`deleteOne${modelUppercaseFirst}`] : false;
      }),
      catchError(this.handleError<boolean>(
        'updateItem', false, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  addSubDocument<T, R = any>(model: Model, itemId: string, subDocModel: Model, subDoc: T): Observable<R | undefined> {
    const modelUppercaseFirst = firstToUpperCase(model.name);
    const subDocFieldUppercaseFirst = firstToUpperCase(subDocModel.name);
    return this.apollo.mutate<AddSubDocResponse<R>>({
      mutation: gql`
        mutation AddSubItem($_id: MongoID!, $record: Add${modelUppercaseFirst}${subDocFieldUppercaseFirst}Input!) {
          ${model.name}Add${subDocFieldUppercaseFirst}(_id: $_id, record: $record )  {
            record{
              ${subDocModel.fields}
            }
          }
        }`,
      variables: { _id: itemId, record: subDoc },
    }).pipe(
      map(({ data }) => {
        return data ? data[`${model.name}Add${subDocFieldUppercaseFirst}`].record : undefined;
      }),
      catchError(this.handleError<undefined>(
        'addSubDocument', undefined, ApolloErrorHandlerType.DISCREET
      ))
    );
  }
  updateSubDocument<T, R = any>(model: Model, itemId: string, subDocModel: Model, subDocId: string, subDoc: Partial<T>): Observable<boolean> {
    const modelUppercaseFirst = firstToUpperCase(model.name);
    const subDocFieldUppercaseFirst = firstToUpperCase(subDocModel.name);
    return this.apollo.mutate<UpdateSubDocResponse<R>>({
      mutation: gql`
        mutation UpdateSubItem($_id: MongoID!, $subId: MongoID!, $record: Update${modelUppercaseFirst}${subDocFieldUppercaseFirst}Input!) {
          ${model.name}Update${subDocFieldUppercaseFirst}(_id: $_id, subId: $subId, record: $record ){
            record {
              ${subDocModel.fields}
            }
          }
        }`,
      variables: { _id: itemId, subId: subDocId, record: subDoc },
    }).pipe(
      map(({ data }) => {
        return data ? !!data[`${model.name}Update${subDocFieldUppercaseFirst}`].record : false;
      }),
      catchError(this.handleError<boolean>(
        'updateSubDocument', false, ApolloErrorHandlerType.DISCREET
      ))
    );
  }
  removeSubDocument<T>(model: Model, itemId: string, subDocModel: Model, subDocId: string): Observable<boolean> {
    const subDocFieldUppercaseFirst = firstToUpperCase(subDocModel.name);
    return this.apollo.mutate<RemoveSubDocResponse<T>>({
      mutation: gql`
          mutation RemoveSubItem($_id: MongoID!, $subId: MongoID!) {
            ${model.name}Remove${subDocFieldUppercaseFirst}(_id: $_id, subId: $subId ) {
              record{
                ${subDocModel.fields}
              }
            }
          }`,
      variables: { _id: itemId, subId: subDocId },
    }).pipe(
      map(({ data }) => {
        return data ? !!data[`${model.name}Remove${subDocFieldUppercaseFirst}`].record : false;
      }),
      catchError(this.handleError<boolean>(
        'removeSubDocument', false, ApolloErrorHandlerType.DISCREET
      ))
    );
  }
}

