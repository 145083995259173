import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';

import { FormlyMatAutocompleteComponent } from './ngx-formly-material-autocomplete.component';

@NgModule({
  declarations: [
    FormlyMatAutocompleteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'autocomplete',
          component: FormlyMatAutocompleteComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    FormlyMatFormFieldModule
  ],
  exports: [
    FormlyMatAutocompleteComponent
  ]
})
export class FormlyMatAutocompleteModule { }
