<div class="p-10">
    <p *ngIf="!item">Agregar Elemento</p>
    <p *ngIf="!!item">Editar Elemento</p>
    <div>
        <form [formGroup]="form" (ngSubmit)="submitForm()">
            <formly-form class="frow justify-between" [form]="form" [fields]="fields" [options]="options" [model]="model"
                (modelChange)="modelChange($event)">
            </formly-form>
            <div class="frow row-end">
                <div class="col-xs-1-4 ml-10">
                    <button mat-flat-button color="primary" type="button" (click)="close()">Cancelar</button>
                </div>
                <div class="col-xs-1-4 ml-10" *ngIf="!!item && !data?.readonly">
                    <button mat-flat-button type="submit" color="primary"
                        [disabled]="!changed || !form.valid">Actualizar</button>
                </div>
                <div class="col-xs-1-4 ml-10" *ngIf="!item">
                    <button mat-flat-button type="submit" color="primary" [disabled]="!form.valid">Agregar</button>
                </div>
            </div>
        </form>
    </div>
</div>