import { FormlyFieldConfig } from '@ngx-formly/core';
import { FileUploadService } from '../file-upload.service';

export default function initFileField(fields: FormlyFieldConfig[], field: string, folder: string, fileUploadService: FileUploadService) {
    const { fileRequestMap, fileResponseMap } = fileUploadService.getMaps(folder, field);
    const fileField = fields.find(f => f.key === field);
    if (fileField && fileField.templateOptions && fileField.templateOptions['uploadOptions']) {
        fileField.templateOptions['uploadOptions'].paramName = field;
        fileField.templateOptions['uploadOptions'].requestMap = fileRequestMap;
        fileField.templateOptions['uploadOptions'].responseMap = fileResponseMap;
    }
    return fields;
}