<div class="fileUploadContainer">
  <mat-icon class="firstIcon">{{fileIcon}}</mat-icon>
  <div class="infoContainer">
    <div class="file">
      <div class="fileinfo" *ngIf="!!file">
        <div class="filename">{{file.name  ? file.name : 'Null'}}</div>
        <div class="filesize">{{file.size | fileSize}}</div>
      </div>
      <div class="fileinfo" *ngIf="!!uploadedFile">
        <div class="filename">
          <a class="dark" [href]="url" target="_blank">
            {{uploadedFile.name  ? uploadedFile.name : 'Null'}}
            <mat-icon>open_in_new</mat-icon>
          </a>
        </div>
        <div class="filesize">{{uploadedFile.size | fileSize}}</div>
      </div>
      <div class="buttonsContainer">
        <button *ngIf="uploadedFile?.url" mat-icon-button type="button" [matTooltip]="url" [cdkCopyToClipboard]="url">
          <mat-icon>content_copy</mat-icon>
        </button>
        <button *ngIf="!!file" mat-icon-button type="button" [matTooltip]="uploadFileTooltip"
          (click)="upload($event)">
          <mat-icon>publish</mat-icon>
        </button>
        <button *ngIf="!!file" mat-icon-button type="button" [matTooltip]="removeFileTooltip" (click)="removeFile()">
          <mat-icon>clear</mat-icon>
        </button>
        <button *ngIf="!!uploadedFile && !hideRemove" mat-icon-button type="button" [matTooltip]="removeFileTooltip" (click)="removeFile()">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-progress-bar *ngIf="showProgressBar" matLine mode="determinate" [value]="progress"></mat-progress-bar>
  <!-- <mat-error matLine class="mat-error" role="alert">
    <formly-validation-message [field]="field"></formly-validation-message>
  </mat-error> -->
</div>