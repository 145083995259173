import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth/auth.component';

import { LoginComponent } from './views/login/login.component';
import { RequestPasswordComponent } from './views/request-password/request-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';


const routes: Routes = [{
  path: '',
  component: AuthComponent,
  children: [
    {
      path: 'login',
      component: LoginComponent
    }, {
      path: 'login/operator',
      component: LoginComponent
    }, {
      path: 'request-password',
      component: RequestPasswordComponent
    }, {
      path: 'reset-password',
      component: ResetPasswordComponent
    },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: '**', redirectTo: 'login' },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
