import { Validators } from '@angular/forms';

const appValidators = {
    validators: [
        { name: 'email', validation: Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) },
        { name: 'phone', validation: Validators.pattern(/([0-9]{2})[-. /]?([0-9]{2})[-. /]?([0-9]{2})[-. /]?([0-9]{2})[-. /]?([0-9]{2})$/) },
        { name: 'text-only', validation: Validators.pattern(/^[A-Za-zÀ-ÖØ-öø-ÿ -]*$/) },
        { name: 'text-number', validation: Validators.pattern(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9 -]*$/) },
        { name: 'number', validation: Validators.pattern(/^[0-9]+(\.[0-9]+)?$/) }
    ],
    validationMessages: [
        { name: 'email', message: 'No es un correo válido' },
        { name: 'phone', message: 'No es un teléfono válido' },
        { name: 'text-only', message: 'Solo se permiten letras' },
        { name: 'text-number', message: 'Solo se permiten letras y números' },
        { name: 'number', message: 'Solo se permiten números' },
        { name: 'required', message: 'Este campo es obligatorio' },
    ],
};
export default appValidators;