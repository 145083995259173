import { Observable } from 'rxjs';
import { RequestTableOptions, ResponseTableItems } from '../components/dynamic-table';
import { HandleError } from './apollo-error-handler.service';
import { AdminService, Model, QueryOptions } from './admin.service';


export class ModelAdminService<T = any> {

  constructor(
    protected handleError: HandleError,
    public model: Model,
    protected adminService: AdminService,
  ) {
  }

  getTableSourceFunction(searchFields?: string | string[]): (tableParams: RequestTableOptions) => Observable<ResponseTableItems> {
    return this.adminService.getTableSourceFunction<T>(this.model, searchFields);
  }

  query(queryOptions: QueryOptions): Observable<ResponseTableItems> {
    return this.adminService.query<T>(this.model, queryOptions);
  }

  addItem(data: T): Observable<T | undefined> {
    return this.adminService.addItem<T>(this.model, data);
  }

  getItem(itemId: string): Observable<T | undefined> {
    return this.adminService.getItem<T>(this.model, itemId);
  }

  getItemPopulated(itemId: string): Observable<T | undefined> {
    return this.adminService.getItemPopulated<T>(this.model, itemId);
  }

  updateItem(itemId: string, data: any): Observable<boolean> {
    return this.adminService.updateItem(this.model, itemId, data);
  }

  deleteItem(itemId: string): Observable<boolean> {
    return this.adminService.deleteItem(this.model, itemId);
  }

  addSubDocument<R = any>(itemId: string, subDocModel: Model, subDoc: T): Observable<R | undefined> {
    return this.adminService.addSubDocument<T, R>(this.model, itemId, subDocModel, subDoc);
  }
  updateSubDocument<R = any>(itemId: string, subDocModel: Model, subDocId: string, subDoc: Partial<T>): Observable<boolean> {
    return this.adminService.updateSubDocument<T, R>(this.model, itemId, subDocModel, subDocId, subDoc);
  }
  removeSubDocument(itemId: string, subDocModel: Model, subDocId: string): Observable<boolean> {
    return this.adminService.removeSubDocument<T>(this.model, itemId, subDocModel, subDocId);
  }
}

