import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

export interface FileUploadState {
  progress: number;
  response?: any;
}

export type RequestMapFn = (request: HttpRequest<FormData>) => Observable<HttpRequest<FormData | File | any>>;
export type ResponseMapFn = (event: HttpResponse<any>, request: HttpRequest<any>) => NgxFormlyMaterialUploadedFile;

export interface NgxFormlyMaterialUploadOptions {
  uploadUrl: string;
  paramName?: string;
  method?: string;
  requestMap?: RequestMapFn;
  responseMap?: ResponseMapFn;
}

export interface NgxFormlyMaterialUploadedFile {
  name: string;
  size: number;
  type: string;
  url: string;
  [key: string]: any;
}

@Injectable()
export class NgxFormlyMaterialFileUploadService {
  // finalRequest?: HttpRequest<any>;

  constructor(private readonly http: HttpClient) { }

  public upload(file: File, options: NgxFormlyMaterialUploadOptions): Observable<FileUploadState> {
    // console.log('upload', file, options);
    const request: HttpRequest<FormData> = this.createRequest(file, options.uploadUrl, options.method, options.paramName);
    let requestObs: Observable<HttpEvent<any>>;
    let finalRequest: HttpRequest<any> = request;
    // console.log('request', request);
    if (options.requestMap) {
      // console.log('options.requestMap', options.requestMap);
      requestObs = options.requestMap(request).pipe(
        switchMap(req => {
          // console.log('switchMap req', req);
          finalRequest = req;
          return this.http.request(req);
        })
      );
    } else {
      requestObs = this.http.request(request);
    }
    return requestObs.pipe(
      filter(this.isSupportedEvent),
      map((event: HttpEvent<unknown>)=> {
        return this.createFileUploadState(event, finalRequest, options.responseMap);
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('requestObs catchError', error);
        throw error.statusText;
      })
    );
  }

  private createRequest(file: File, url: string, method: string = 'POST', paramName: string = 'file'): HttpRequest<FormData> {
    // console.log('createRequest', url, method, paramName);
    const formData: FormData = new FormData();
    formData.append(paramName, file, file.name);
    // console.log('formData', formData);
    return new HttpRequest(method, url, formData, {
      reportProgress: true
    });
  }

  private isSupportedEvent(event: HttpEvent<unknown>): boolean {
    // console.log('isSupportedEvent', event);
    return event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response;
  }

  private createFileUploadState(event: HttpEvent<unknown>, finalRequest: HttpRequest<any>, responseMap?: ResponseMapFn): FileUploadState {
    // console.log('createFileUploadState', event, responseMap);
    if (event.type === HttpEventType.UploadProgress) {
      const { loaded, total} = event;
      const percentDone = Math.round((100 * loaded) / (total ? total : 0));
      return { progress: percentDone };
    }

    if (event.type === HttpEventType.Response) {
      if (event.ok) {
        let response;
        if (responseMap) {
          // console.log('responseMap', responseMap);
          response = responseMap(event, finalRequest);
        } else {
          response = event.body;
        }
        // console.log('response', response);
        return { progress: 100, response };
      } else {
        throw new Error(event.statusText);
      }
    }

    throw new Error('upload error');
  }

}
