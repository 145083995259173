import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';

import { LocationMapModule } from './location-map/location-map.module';

import { FormlyMatLocationComponent } from './ngx-formly-material-location.component';



@NgModule({
  declarations: [
    FormlyMatLocationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'location',
          component: FormlyMatLocationComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    FormlyMatFormFieldModule,
    LocationMapModule.forRoot()
  ],
  exports: [
    FormlyMatLocationComponent
  ]
})
export class FormlyMatLocationModule { }
