<div class="dialogContainer" *ngIf="dialogType=='loading'">
  <mat-spinner></mat-spinner>
  <h1>{{loadingLabel ? loadingLabel: 'Loading'}}</h1>
</div>

<div class="dialogContainer" *ngIf="dialogType=='error'">
  <h1 mat-dialog-title>{{errorTitle ? errorTitle: 'Error' }}</h1>
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="handleButtonClick(button)" [attr.cdkFocusInitial]="i === ((buttons ? buttons.length : 1) - 1)"
      *ngFor="let button of buttons;let i = index">{{button.text}}</button>
    <!-- <button mat-button mat-dialog-close cdkFocusInitial>{{okButton}}</button> -->
  </div>
</div>

<div class="dialogContainer" *ngIf="dialogType=='message'">
  <h1 mat-dialog-title>{{messageTitle}}</h1>
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  <div mat-dialog-actions>
    <!-- <button mat-button (click)="cancelAction()" *ngIf="cancelButton">{{cancelButton}}</button> -->
    <button mat-button (click)="handleButtonClick(button)" [attr.cdkFocusInitial]="i === ((buttons ? buttons.length : 1) - 1)"
    *ngFor="let button of buttons;let i = index">
      {{button.text}}</button>
  </div>
</div>