import gql from 'graphql-tag';

export interface LoginData {
  email: string;
  password: string;
}

export interface AdminLoginResponse {
  loginAdmin: {
    success: boolean;
    token: string;
  };
}

export const LoginMutation = (isOperator?: boolean) => {
  return gql`
  mutation AdminLogin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password${isOperator ? ', isOperator: true' : ''}) {
      success
      token
    }
  }`;
};

export interface LoginDataSuperAdmin {
  email: string;
  password: string;
}

export interface SuperAdminLoginResponse {
  loginSuperAdmin: {
    success: boolean;
    token: string;
  };
}

export const LoginSuperAdminMutation = gql`
  mutation SuperAdminLogin($email: String!, $password: String!) {
    loginSuperAdmin(email: $email, password: $password) {
      success
      token
    }
  }`