<ng-template #flex>
  <br>
  <div *ngFor="let fieldGroupField of field.fieldGroup; let i = index" style="display: flex; flex: 1 0 100%;">
    <formly-field [field]="fieldGroupField" style="flex: 1 1 auto; margin-right: 10px;"></formly-field>
    <button mat-stroked-button [id]="'remove-' + i" type="button" *ngIf="!to?.readonly" (click)="remove(i)"  style="margin-bottom: 15px">
      <mat-icon color="warn">delete</mat-icon>
    </button>
  </div>
  <button mat-stroked-button id="add" type="button" *ngIf="!to?.readonly" (click)="add()">
    <mat-icon color="primary">queue</mat-icon>
  </button>
</ng-template>
<ng-template #accordion>
  <br>
  <mat-accordion class="mat-elevation-z0">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="step === i" (opened)="setStep(i)" *ngFor="let fieldGroupField of field.fieldGroup; let i = index">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ fieldGroupField.templateOptions?.label }}{{i}}</mat-panel-title>
      </mat-expansion-panel-header>
      <formly-field [field]="fieldGroupField"></formly-field>
      <mat-action-row style="justify-content: space-between;">
        <div>
          <button mat-stroked-button [id]="'remove-' + i" type="button" *ngIf="!to?.readonly" (click)="remove(i)">
          <mat-icon color="warn">delete</mat-icon>
          </button>
          <button mat-stroked-button id="add" type="button" *ngIf="!to?.readonly" (click)="add(i + 1)">
            <mat-icon color="primary">queue</mat-icon>
          </button>
        </div>
        <div>
          <button mat-button color="warn" (click)="prevStep($event)" *ngIf="i > 0">Previous</button>
          <button mat-button color="primary" (click)="nextStep($event)" *ngIf="i < (field.fieldGroup?.length || 0) - 1">Next</button>
        </div>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
  <button mat-stroked-button id="add" type="button" *ngIf="!field.fieldGroup?.length && !to?.readonly" (click)="add(0)">
    <mat-icon color="primary">queue</mat-icon>
  </button>
</ng-template>
<ng-container [ngSwitch]="to.arrayAppearance">
  <ng-container *ngSwitchCase="'flex'" [ngTemplateOutlet]="flex"></ng-container>
  <ng-container *ngSwitchCase="'accordion'" [ngTemplateOutlet]="accordion"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="flex"></ng-container>
</ng-container>