import { Component, DoCheck, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, OnChanges, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/material';
import { Observable, of } from 'rxjs';
import { FieldTypeConfig } from '@ngx-formly/core';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-ngx-formly-material-autocomplete',
  templateUrl: './ngx-formly-material-autocomplete.component.html',
  styleUrls: ['./ngx-formly-material-autocomplete.component.scss'],
})
export class FormlyMatAutocompleteComponent extends FieldType<FieldTypeConfig> implements OnInit, DoCheck {
  filter?: Observable<{value: any; label: string;}[]>;
  displayFn?: (value: any) => string;
  private toDiffer?: KeyValueDiffer<string, any>;
  constructor(private keyValueDiffers: KeyValueDiffers) {
    super();
  }

  ngOnInit(): void {
    this.filter = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((term) => {
        // console.log('autocomplete valuechanges', term, this.props['filter']);
        if (!this.props['filter']){
          return of([]);
        }
        return this.props['filter'](term, this.field) as Observable<{ value: any; label: string; }[]>;
      }),
    );
    try {
      this.toDiffer = this.keyValueDiffers.find(this.to || {}).create();
    } catch (error) {
      console.error(error);
    }
  }

  defaultDisplayFn(value: any): string {
    return value?.label || value || '';
  }

  toChanged(changes: KeyValueChanges<string, any>) {
    /* If you want to see details then use
      changes.forEachRemovedItem((record) => ...);
      changes.forEachAddedItem((record) => ...);
      changes.forEachChangedItem((record) => ...);
    */
    this.displayFn = this.props['displayFn'];
  }

  ngDoCheck(): void {
    const changes = this.toDiffer?.diff(this.to);
    if (changes) {
      this.toChanged(changes);
    }
  }
}
