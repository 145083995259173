import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material/form-field';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

@Component({
  selector: 'app-ngx-formly-material-location',
  templateUrl: './ngx-formly-material-location.component.html',
  styleUrls: ['./ngx-formly-material-location.component.scss']
})
export class FormlyMatLocationComponent<F extends FormlyFieldConfig<FormlyFieldProps>> extends FieldType<F> implements OnInit {
  get locationFormControl(): UntypedFormControl {
    if (this.formControl) {
      return this.formControl as UntypedFormControl;
    } else {
      return new UntypedFormControl(0);
    }
  }

  override defaultOptions: any = {
    templateOptions: {
      floatLabel: 'always'
    },
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
