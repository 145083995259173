import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';

import { NgxFormlyMaterialArrayComponent } from './ngx-formly-material-array.component';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';



@NgModule({
  declarations: [NgxFormlyMaterialArrayComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatExpansionModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'array',
          component: NgxFormlyMaterialArrayComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    FormlyMatFormFieldModule
  ],
  exports: [NgxFormlyMaterialArrayComponent]
})
export class NgxFormlyMaterialArrayModule { }
