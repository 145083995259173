import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DIALOG_CONFIG, DynamicDialogConfig } from '../dynamic-dialog.config';

export type DynamicDialogType = 'loading' | 'error' | 'message';

export interface DynamicDialogData {
  dialogType: DynamicDialogType;
  message: string;
  messageTitle?: string;
  buttons?: DialogButton[];
}

export interface DialogButton {
  text: string;
  role?: string | 'cancel';
}

@Component({
  selector: 'deva-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  styleUrls: ['./dynamic-dialog.component.scss']
})
export class DynamicDialogComponent implements OnInit {
  dialogType: DynamicDialogType = 'loading';
  message: string;
  messageTitle: string;
  buttons: DialogButton[];

  loadingLabel: string | undefined;
  errorTitle: string | undefined;
  constructor(
    public dialogRef: MatDialogRef<DynamicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DynamicDialogData,
    @Optional() @Inject(DIALOG_CONFIG) private config: DynamicDialogConfig
  ) {
    this.dialogType = data.dialogType;
    this.message = data.message;
    this.messageTitle = data.messageTitle ? data.messageTitle :
      data.dialogType === 'error' ? 'Error' : data.dialogType === 'loading' ? 'Loading...' : 'Message';

    this.buttons = data.buttons ? data.buttons : [{ text: 'ACCEPT', role: 'cancel' }];

    this.loadingLabel = this.config.loadingLabel;
    this.errorTitle = this.config.errorTitle;
  }

  ngOnInit() {
  }

  handleButtonClick(button: DialogButton) {
    let role = undefined;
    if (button.role) {
      role = button.role;
      if (role === 'cancel') {
        this.dialogRef.close();
      }
      this.dialogRef.close({ role, button });
    } else {
      this.dialogRef.close({ button });
    }
  }
}
