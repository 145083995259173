import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FileTypeConfig, FILE_TYPE_CONFIG } from '../file-type-config';
import { NgxFormlyMaterialFileUploadService, NgxFormlyMaterialUploadedFile, NgxFormlyMaterialUploadOptions } from '../file-upload.service';

const DONE_ICON = 'cloud_done';
const UPLOAD_ICON = 'cloud_upload';

export interface FileUploadConfig {
  uploadedFileMap: FileMapFn
}


export type FileMapFn = (file: any) => NgxFormlyMaterialUploadedFile;

@Component({
  selector: 'ngx-formly-material-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [NgxFormlyMaterialFileUploadService]
})
export class FileUploadComponent implements OnInit, OnChanges, OnDestroy {  
  progress = 0;
  uploadError?: string;
  
  @Input() file?: File;
  @Input() uploadOptions?: NgxFormlyMaterialUploadOptions;

  @Input() hideRemove?: boolean;

  @Input() uploadedFile?: NgxFormlyMaterialUploadedFile;
  @Output() uploadedFileChange = new EventEmitter<NgxFormlyMaterialUploadedFile>();

  @Output() deleteFile = new EventEmitter<any>();
  
  fileIcon: string = UPLOAD_ICON;

  private progressSubscription?: Subscription;

  constructor(
    @Inject(FILE_TYPE_CONFIG) public readonly fileTypeConfig: FileTypeConfig,
    private uploadService: NgxFormlyMaterialFileUploadService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.fileIcon = this.file ? UPLOAD_ICON : DONE_ICON;
  }

  upload(event: Event) {
    event.preventDefault();
    // console.log('uploadFile');
    if (!this.file || !this.uploadOptions) {
      return;
    }
    this.progressSubscription = this.uploadService.upload(
      this.file,
      this.uploadOptions
      ).subscribe(
        (uploadState: any) => {
          // console.log('uploadState', uploadState);
          this.progress = uploadState.progress;
          if (this.progress === 100 && uploadState.response) {
            const response = uploadState.response;
            this.uploadedFileChange.emit(response);
          }
          if (this.progress === 100 && !this.uploadError) {
            this.fileIcon = DONE_ICON;
            setTimeout(() => this.progressSubscription = undefined, 1000);
          }
        },
        (error: any) => {
          console.log('Upload error', error);
          this.uploadError = error;
          this.uploadedFileChange.emit(undefined);
        });
  }

  ngOnDestroy() {
    this.cancelUpload();
  }

  removeFile() {
    this.cancelUpload();
    this.deleteFile.emit();
  }

  get showProgressBar(): boolean {
    return !!this.progressSubscription && !this.uploadError;
  }

  private cancelUpload() {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
  }

  get url(): string {
    return this.uploadedFile?.url ? this.uploadedFile?.url : '';
  }

  get uploadFileTooltip(): string {
    return this.fileTypeConfig?.uploadFileTooltip ? this.fileTypeConfig?.uploadFileTooltip : '';
  }

  get removeFileTooltip(): string {
    return this.fileTypeConfig?.removeFileTooltip ? this.fileTypeConfig?.removeFileTooltip : '';
  }
}
