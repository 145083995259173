import { Component, AfterViewInit, OnDestroy, Input, ElementRef, OnChanges, forwardRef, HostBinding, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Observable, Subject } from 'rxjs';
import { LocationMapService } from './location-map.service';
import { GoogleMap } from '@angular/google-maps';
import MARKER_64_PNG from './marker';


export class MyLocation {
  constructor(public latitude: number, public longitude: number) { }
}

const MAX_LNG = 180;
const MAX_LAT = 90;

export function createLocationValidator() {
  return (c: UntypedFormControl) => {
    // console.log('location validator', c.value);
    const err = {
      requiredError: {
        given: c.value,
      }
    };
    if (!c.value || c.value.length < 0 ) {
      return err;
    }
    if (c.value[0] === undefined || c.value[1] === undefined) {
      return err;
    }
    const lngVal = parseInt(c.value[0], 10);
    const latVal = parseInt(c.value[1], 10);
    if (!lngVal || !latVal) {
      return err;
    }
    if (lngVal < -MAX_LNG || lngVal > MAX_LNG || latVal < -MAX_LAT || latVal > MAX_LAT) {
      return err;
    }
    return (!c.value) ? err : null;
  };
}

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: LocationMapComponent },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LocationMapComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => LocationMapComponent), multi: true },
  ]
})
export class LocationMapComponent implements AfterViewInit, OnChanges, MatFormFieldControl<MyLocation>, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  parts: UntypedFormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  ngControl = null;
  errorState = false;
  controlType = 'app-location-map';
  @HostBinding('id')
  id = `app-location-map-${LocationMapComponent.nextId++}`;
  @HostBinding('attr.aria-describedby')
  describedBy = '';
  @HostBinding('class.example-floating')
  shouldLabelFloat = true;

  get empty() {
    const value = this.parts.value;
    return !value.longitude && !value.latitude ;
  }

  private placeholderPriv: string = '';
  @Input()
  get placeholder(): string { return this.placeholderPriv; }
  set placeholder(value: string) {
    this.placeholderPriv = value;
    this.stateChanges.next();
  }

  private requiredPriv = false;
  @Input()
  get required(): boolean { return this.requiredPriv; }
  set required(value: boolean) {
    this.requiredPriv = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private disabledPriv = false;
  @Input()
  get disabled(): boolean { return this.disabledPriv; }
  set disabled(value: boolean) {
    this.disabledPriv = coerceBooleanProperty(value);
    this.disabledPriv ? this.parts.disable() : this.parts.enable();
    this.options = { ...this.options, draggable: !this.disabledPriv};
    this.stateChanges.next();
  }

  @Input()
  set value(location: MyLocation | null) {
    const {longitude, latitude } = location || new MyLocation(
      this.defaultLocation[0], this.defaultLocation[1]
    );
    this.parts.setValue({longitude, latitude});
    this.stateChanges.next();
  }
  get value(): MyLocation | null {
    const { value: { longitude, latitude } } = this.parts;
    if (!!longitude && !!latitude) {
      return new MyLocation(longitude, latitude);
    }
    return null;
  }

  // MAP
  private defaultLocationPriv: [number, number] = [0, 0];
  @Input()
  set defaultLocation(value: [number, number]) {
    // console.log('defaultLocation value', value);
    if (value && value[0] && value[1]) {
      this.defaultLocationPriv = value;
      if (this.parts.value.lat === 0 && this.parts.value.lng === 0) {
        const center = { lat: value[1], lng: value[0] };
        // this.parts.setValue(center);
        this.updateMapCenter(center)
      }
    }
  }get defaultLocation(): [number, number] { return this.defaultLocationPriv; }

  private restrictionBoundsPriv: google.maps.LatLngBoundsLiteral = { north: MAX_LAT, south: -MAX_LAT, west: -MAX_LNG, east: MAX_LNG };
  @Input() set restrictionBounds(value: google.maps.LatLngBoundsLiteral) {
    // console.log('value', value);
    if (value) {
      this.restrictionBoundsPriv = value;
      this.options.restriction = { latLngBounds: value, strictBounds: true };
    } else {
      delete this.options.restriction;
    }
    this.options = Object.assign({}, this.options);
  } get restrictionBounds(): google.maps.LatLngBoundsLiteral { return this.restrictionBoundsPriv; }

  @ViewChild(GoogleMap) googleMap?: GoogleMap;
  options: google.maps.MapOptions = {
    zoom: 15
  };
  mapCenter: google.maps.LatLngLiteral = { lat: this.defaultLocation[1], lng: this.defaultLocation[0] };
  apiLoaded?: Observable<boolean>;
  markerPosition: any;
  markerImg: string = `data:image/png;base64,${MARKER_64_PNG}`;
  constructor(
    private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>,
    private locationMapService: LocationMapService,
  ) {
    this.parts = new UntypedFormGroup({
      lng: new UntypedFormControl(this.defaultLocation[0], [Validators.required]),
      lat: new UntypedFormControl(this.defaultLocation[1], [Validators.required]),
    });
    fm.monitor(elRef, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.parts.valueChanges.subscribe(value => {
      // console.log('parts.valueChanges', value, this.propagateChange)
      if (this.propagateChange) {
        this.propagateChange(this.parseLocationValue(value));
      }
    });

    this.apiLoaded = this.locationMapService.apiLoaded;
  }
  propagateChange: any = () => { };
  propagateTouched: any = () => { };
  validateFn: any = () => { };

  ngAfterViewInit() {
  }

  parseLocationValue(partsValue: {lng: any, lat: any}) {
    // console.log('parseLocationValue', partsValue);
    const location: [number, number] = [0, 0];
    location[0] = parseFloat(partsValue.lng);
    location[1] = parseFloat(partsValue.lat);
    // console.log('parseLocationValue location', location);
    return location;
  }

  ///// Custom formControl
  ngOnChanges(inputs: any) {
    // console.log('ngOnChanges', inputs);
    this.validateFn = createLocationValidator();
  }

  writeValue(value: [number, number]) {
    // console.log('writeValue',value);
    if (value) {
      const lng = value[0] !== undefined ? value[0] : this.defaultLocation[0];
      const lat = value[1] !== undefined ? value[1] : this.defaultLocation[1];
      this.parts.setValue({ lng, lat });
      this.updateMapCenter({ lng, lat });
    }
  }


  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
  ////////

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.propagateTouched();
  }

  // Map

  mapCenterChange(event: any) {
    // console.log('mapCenterChange', event);
    if (this.googleMap) {
      // console.log('googleMap', this.googleMap.getCenter());
      const center = this.googleMap.getCenter();
      this.parts.setValue({ lat: center?.lat(), lng: center?.lng() });
    }
  }

  updateMapCenter(center: {lng: number, lat: number}) {
    // console.log('updateMapCenter', center);
    this.mapCenter = center;
  }
}
