export enum AdminPaymentStatus {
    INACTIVE = "INACTIVE",
    ACTIVE = "ACTIVE"
}
export interface PossibleResidents {
    _id: string;
    shortId: string;
}
export enum ResidentAdminPaymentStatus {
    WAITING="WAITING",
    PAID="PAID"
}
export interface ResidentAdminPayment {
    _id: string;
    resident: string;
    status: ResidentAdminPaymentStatus;
    payDate?: Date;
}
export interface InvoiceRequest {
    _id: string;
    residentId: string;
    status: string;
}