import { MapLocation } from './MapLocation';

export enum AccessStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE'
}

export enum AccessType {
    GUEST = 'GUEST',
    SERVICE = 'SERVICE'
}
export enum AccessTime {
    ACTUAL = 'Actual',
    PROXIMO = 'Próximo',
    PASADO = 'Pasado'
}
export interface AccessBase {
    _id: string;
    name: string;
    parking?: boolean;
    knownCar?: boolean;
    carPlate?: string;
    status: AccessStatus;
    accessRegistry?: Date;
    departureRegistry?: Date;
    admin?: string;
    resident?: string;
    accessDate: Date;
    allDay?: boolean;
    duration?: number;
    accessType: AccessType;
    leaveDate?: Date;
}
// Access item for table
export interface AccessTable extends AccessBase {
    expired: boolean;
    time: AccessTime;
}

export interface AccessAddress {
    label: string;
    address: string;
    mapLocation: MapLocation;
}
