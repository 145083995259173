import gql from 'graphql-tag';


export interface AdminLogoutResponse {
  logoutAdmin: {
    success: boolean;
  };
}

export const LogoutQuery = gql`
  query AdminLogout {
    logoutAdmin {
      success
    }
  }`;

export interface SuperAdminLogoutResponse {
  logoutSuperAdmin: {
    success: boolean;
  };
}

export const LogoutSuperAdminQuery = gql`
  query SuperAdminLogout {
    logoutSuperAdmin {
      success
    }
  }`;