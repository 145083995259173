import { Component, Inject, Optional } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FORMLY_CONFIG, FormlyExtension, FormlyFieldConfig, FormlyFormBuilder, FormlyTemplateOptions } from "@ngx-formly/core";
import { assignFieldValue, clone, getFieldValue, hasKey, isNullOrUndefined } from './utils';
import { findControl, registerControl, unregisterControl } from './field-form-utils';
import { FieldType } from '@ngx-formly/material/form-field';
import { FormlyFieldConfigCache } from '@ngx-formly/core/lib/models';

export interface FormlyArrayFieldConfig extends FormlyFieldConfig {
  templateOptions?: FormlyTemplateOptions & { arrayAppearance?: 'flex' | 'accordion' };
}

export interface FieldArrayTypeConfig extends FormlyFieldConfig {
  formControl: FormArray;
  templateOptions: NonNullable<FormlyFieldConfig['templateOptions']>;
  options: NonNullable<FormlyFieldConfig['options']>;
}

@Component({
  selector: 'ngx-formly-material-array',
  templateUrl: './ngx-formly-material-array.component.html',
  styleUrls: [
    './ngx-formly-material-array.component.scss',
  ]
})
export class NgxFormlyMaterialArrayComponent<F extends FormlyFieldConfig = FormlyFieldConfig> extends FieldType<any> implements FormlyExtension {
  override field!: F;
  override defaultOptions: any = {
    defaultValue: [],
  };
  step = 0;

  // override get formControl()  {
  //   return this.field.formControl as FormArray;
  // }

  constructor(@Inject(FORMLY_CONFIG) @Optional() builder?: FormlyFormBuilder) {
    super();

    if (builder instanceof FormlyFormBuilder) {
      console.warn(`NgxFormly: passing 'FormlyFormBuilder' to '${this.constructor.name}' type is not required anymore, you may remove it!`);
    }
  }

  onPopulate(field: FormlyFieldConfig) {
    if (!field.formControl && hasKey(field)) {
      const control = findControl(field);
      registerControl(field, control ? control : new FormArray([], { updateOn: field.modelOptions?.updateOn }));
    }

    field.fieldGroup = field.fieldGroup || [];

    const length = Array.isArray(field.model) ? field.model.length : 0;
    if (field.fieldGroup.length > length) {
      for (let i = field.fieldGroup.length - 1; i >= length; --i) {
        unregisterControl(field.fieldGroup[i], true);
        field.fieldGroup.splice(i, 1);
      }
    }

    for (let i = field.fieldGroup.length; i < length; i++) {
      const f = { ...clone(typeof field.fieldArray === 'function' ? field.fieldArray(field) : field.fieldArray) };
      if (f.key !== null) {
        f.key = `${i}`;
      }
      field.fieldGroup.push(f);
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep(event?: Event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.step++;
  }

  prevStep(event?: Event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.step--;
  }

  add(i?: number, initialModel?: any, { markAsDirty } = { markAsDirty: true }) {
    i = isNullOrUndefined(i) ? this.field?.fieldGroup?.length : i;
    if (!this.model) {
      assignFieldValue(this.field, []);
    }

    this.model.splice(i, 0, initialModel ? clone(initialModel) : undefined);
    if (this.field?.fieldGroup && i != undefined) {
      this.markFieldForCheck(this.field.fieldGroup[i]);
    }
    this._build();

    markAsDirty && this.formControl.markAsDirty();
    if (i != null) {
      this.setStep(i);
    }
  }

  remove(i: number, { markAsDirty } = { markAsDirty: true }) {
    if (!this.field?.fieldGroup) {
      return;
    }
    this.model.splice(i, 1);
    this.field.fieldGroup.splice(i, 1);
    this.field.fieldGroup.forEach((f, key) => f.key = `${key}`);
    unregisterControl(this.field.fieldGroup[i], true);
    this._build();
    markAsDirty && this.formControl.markAsDirty();
  
    const items = this.field.fieldGroup?.length || 0;
    if (!items || i < 1) {
      this.setStep(0);
    } else {
      if (items - 1 >= i) {
        this.setStep(i);
      } else {
        this.setStep(items - 1);
      }
    }
  }

  private _build() {
    const fields = (this.field as FormlyFieldConfigCache).formControl?._fields ?? [this.field];
    fields.forEach((f) => (this.options as any).build(f));
    this.options.fieldChanges?.next({
      field: this.field,
      value: getFieldValue(this.field),
      type: 'valueChanges',
    });
  }

  private updateArrayElementKey(f: FormlyFieldConfig, newKey: string) {
    if (hasKey(f)) {
      f.key = newKey;
      return;
    }

    if (!f.fieldGroup?.length) {
      return;
    }

    for (let i = 0; i < f.fieldGroup.length; i++) {
      this.updateArrayElementKey(f.fieldGroup[i], newKey);
    }
  }

  private markFieldForCheck(f: FormlyFieldConfig) {
    if (!f) {
      return;
    }

    f.fieldGroup?.forEach((c: any) => this.markFieldForCheck(c));
    if (f.hide === false) {
      (this.options as any)._hiddenFieldsForCheck.push(f);
    }
  }
}
