import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';


import { GoogleMapsApiService } from './google-maps-api.service';
import { GoogleMapsApiConfig, GOOGLE_MAPS_API_CONFIG } from './google-maps-api.config';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
  ],
})
export class GoogleMapsApiModule {
  constructor(
    @Optional() @SkipSelf() parentModule: GoogleMapsApiModule,
  ) {
    if (parentModule) {
      throw new Error(
        'GoogleMapsApiModule is already loaded. Import it in the AppModule only');
    }
  }
  static forRoot(config: GoogleMapsApiConfig = {apiKey: ''}): ModuleWithProviders<GoogleMapsApiModule> {
    const actualConfig: GoogleMapsApiConfig = {
      apiKey: config.apiKey ? config.apiKey : '',
      ...(config.libraries ? { libraries: config.libraries} : {})
    };

    return {
      ngModule: GoogleMapsApiModule,
      providers: [
        { provide: GOOGLE_MAPS_API_CONFIG, useValue: actualConfig },
        GoogleMapsApiService
      ]
    };
  }
}
