import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { TimeSelectComponent } from './time-select.component';

@NgModule({
    declarations: [
        TimeSelectComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
    ],
    exports: [
        TimeSelectComponent
    ]
})
export class TimeSelectModule { }
