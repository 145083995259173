import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, OnInit, OnChanges, OnDestroy, Input, ElementRef, HostListener, forwardRef, SimpleChanges
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';

export class MyTime {
  constructor(public minutes: string, public hours: string) { }
}
export function createTimeValidator() {
  return (c: UntypedFormControl) => {
    // console.log('c', c)
    const err = {
      requiredError: {
        given: c.value,
      }
    };
    return (c.value === undefined || c.value == null) ? err : null;
  };
}
export interface TimeObject { hours: number; minutes: number; seconds: number; milliseconds: number; }
const ZERO: TimeObject = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };
export function parseTimeToObject(time: number): TimeObject {
  const hr = (60 * 60 * 1000);
  const min = (60 * 1000);
  const sec = 1000;
  const hours = Math.floor(time / hr);
  const minutes = Math.floor((time - (hours * hr)) / min);
  const seconds = Math.floor((time - (((hours * hr) + (minutes * min)))) / sec);
  const milliseconds = Math.floor(time - (((hours * hr) + (minutes * min) + (seconds * sec))));
  return { hours, minutes, seconds, milliseconds };
}
export function parseObjectTime(time: Partial<TimeObject>) {
  const hr = (60 * 60 * 1000);
  let ms = 0;
  const min = (60 * 1000);
  const sec = 1000;
  ms += time.hours ? time.hours * hr : 0;
  ms += time.minutes ? time.minutes * min : 0;
  ms += time.seconds ? time.seconds * sec : 0;
  ms += time.milliseconds ? time.milliseconds : 0;
  return ms;
}

// Handles time in matter of milliseconds (0 - 86,399,999){0 - 23hrs 59min 59seg 999ms}
@Component({
  selector: 'app-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimeSelectComponent), multi: true },
    { provide: MatFormFieldControl, useExisting: TimeSelectComponent },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TimeSelectComponent), multi: true },
  ]
})
export class TimeSelectComponent implements OnInit, OnChanges, MatFormFieldControl<number>, OnDestroy, ControlValueAccessor {
  static nextId = 0;

  // @HostListener('[class.floating]')
  shouldLabelFloat: any;
  @HostListener('[id]') id;
  @HostListener('[attr.aria-describedby]') describedBy;

  minutesOptions: any[];
  hoursOptions: any[];

  parts: UntypedFormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  ngControl = null;
  errorState = false;
  controlType = 'app-time-select';

  autofilled?: boolean | undefined;
  userAriaDescribedBy?: string | undefined;
  get empty() {
    const { value: { hours, minutes } } = this.parts;
    return !hours && !minutes;
  }

  @Input() placeholder: string = '';

  private requiredPriv = false;
  @Input()
  get required(): boolean { return this.requiredPriv; }
  set required(value: boolean) {
    this.requiredPriv = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private disabledPriv = false;
  @HostListener('[disabled]')
  @Input()
  get disabled(): boolean { return this.disabledPriv; }
  set disabled(value: boolean) {
    this.disabledPriv = coerceBooleanProperty(value);
    this.disabledPriv ? this.parts.disable() : this.parts.enable();
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  private valuePrivate: number | null = 0;
  @Input()
  get value(): number | null {
    return this.valuePrivate;
  }
  set value(value: number | null) {
    // console.log('set value', value);
    if (value !== this.value) {
      this.valuePrivate = value;
      this.parts.patchValue(value ? parseTimeToObject(value) : ZERO);
      this.stateChanges.next();
    }
  }

  propagateChange: (_: any) => void = (_: any) => { };
  propagateTouched: () => void = () => { };
  validateFn: any = () => { };

  constructor(private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>) {
    // console.log('app time select constructor');
    this.parts = new UntypedFormGroup({
      hours: new UntypedFormControl(0, [Validators.required]),
      minutes: new UntypedFormControl(0, [Validators.required]),
      seconds: new UntypedFormControl(0, [Validators.required]),
      milliseconds: new UntypedFormControl(0, [Validators.required]),
    });
    fm.monitor(elRef, true).subscribe(origin => {
      // console.log('fm.monitor');
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.minutesOptions = [];
    for (let i = 0; i <= 59; i++) {
      this.minutesOptions.push({ label: i < 10 ? `0${i}` : i, value: i });
    }
    this.hoursOptions = [];
    for (let i = 0; i <= 23; i++) {
      this.hoursOptions.push({ label: i < 10 ? `0${i}` : i, value: i });
    }
    this.parts.valueChanges.subscribe(value => {
      // console.log('parts.valueChanges', value);
      if (this.propagateChange) {
        // console.log('parts valueChanges', value, this.value);
        const obj = this.value ? parseTimeToObject(this.value) : ZERO;
        // console.log('parts valueChanges obj', obj);
        if ((obj.hours !== value.hours || obj.minutes !== value.minutes)) {
          // console.log('parts valueChanges propagateChange');
          this.propagateChange(parseObjectTime(value));
          this.propagateTouched();
          this.stateChanges.next();
        }
      }
    });

    this.id = `app-time-select-${TimeSelectComponent.nextId++}`;
    this.describedBy = '';
  }

  ngOnInit() {
    if (isNaN(this.parts.value.hours) || isNaN(this.parts.value.minutes)) {
      this.parts.setValue(ZERO);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('ngOnChanges', changes);
    if (changes) {
      this.validateFn = createTimeValidator();
    }
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  ///// ControlValueAccessor
  writeValue(value: number) {
    // console.log('writeValue', value, typeof value );
    if (value) {
      this.value = value;
    } else {
      this.value = 0;
    }
  }
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
  ////////

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }

  onContainerClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }


  handleClick(event: MouseEvent) {
    // console.log('selectClick', event);
    event.preventDefault();
    event.stopPropagation();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

}
