<div class="locationMapInputContainer">
  <div class="locationMapContainer">
    <div class="googleMapWrapper" *ngIf="apiLoaded | async">
      <!-- <img class="marker" src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png" alt=""> -->
      <img class="marker" [src]="markerImg">
      <google-map width="100%" height="500px" [options]="options" [center]="mapCenter" (centerChanged)="mapCenterChange($event)">
      </google-map>
    </div>
  </div>
  <div [formGroup]="parts" class="locationInputContainer">
      <span>Longitud:&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <input matInput placeholder="-99.13319984078407" formControlName="lng">
      <span>Latitud:&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <input matInput placeholder="19.432609957536812" formControlName="lat">
    </div>
</div>