import { Component, OnInit, OnChanges, ViewEncapsulation, ViewChild, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarView, CalendarDateFormatter, DAYS_OF_WEEK, CalendarMonthViewComponent } from 'angular-calendar';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { cloneDeep } from 'lodash';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calendar-dates-select',
  templateUrl: './calendar-dates-select.component.html',
  styleUrls: ['./calendar-dates-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarDatesSelectComponent implements OnInit, OnChanges {
  @ViewChild(CalendarMonthViewComponent) calendarMonthViewComponent?: CalendarMonthViewComponent;
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];
  
  calendarSelectedDays: CalendarMonthViewDay[] = [];
  

  locale: string = 'es-MX';

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];

  _selectedDays?: Date[];
  @Input()
  selectedDays?: Date[];
  @Output()
  selectedDaysChange: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ngOnChanges', changes);
    if (changes['selectedDays']) {
      if (changes['selectedDays'].currentValue) {
        this._selectedDays = cloneDeep(this.selectedDays);
        if(this.calendarMonthViewComponent) {
          // console.log('calendarMonthViewComponent', this.calendarMonthViewComponent);
          this.renderSelectedDays(this.calendarMonthViewComponent.view.days);
        }
      }
    }
  }

  ngAfterViewInit() {
    if(this.selectedDays) {
      if (this.calendarMonthViewComponent) {
        this.renderSelectedDays(this.calendarMonthViewComponent.view.days);
      }
    }
  }

  dayClicked(day: CalendarMonthViewDay): void {
    const selectedDateTime = day.date.getTime();
    const dateIndex = this.calendarSelectedDays.findIndex(sd => sd.date.getTime() === selectedDateTime);
    if (dateIndex > -1) {
      delete day.cssClass;
      this.calendarSelectedDays.splice(dateIndex, 1);
    } else {
      this.calendarSelectedDays.push(day);
      day.cssClass = 'cal-day-selected';
    }
    if (this._selectedDays) {
      const selectedDateIndex = this._selectedDays.findIndex(sd => sd.getTime() === selectedDateTime);
      if (selectedDateIndex > -1) {
        this._selectedDays.splice(selectedDateIndex, 1);
      } else {
        this._selectedDays.push(day.date);
      }
    } else {
      this._selectedDays = [day.date];
    }
    this.selectedDaysChange.emit(cloneDeep(this._selectedDays));
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    this.renderSelectedDays(body);
  }

  renderSelectedDays(days: CalendarMonthViewDay[]) {
    this.calendarSelectedDays = [];
    days.forEach((day) => {
      let idx = this.selectedDays?.findIndex(
        (selectedDay) => selectedDay.getTime() === day.date.getTime()
      );
      if (this.selectedDays && idx !== undefined && (idx > -1) ) {
        day.cssClass = 'cal-day-selected';
        this.calendarSelectedDays.push(day);
      }
    });
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

}
