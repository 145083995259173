import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { GoogleMapsModule } from '@angular/google-maps';

import { LocationMapComponent } from './location-map.component';
import { LocationMapService } from './location-map.service';



@NgModule({
  declarations: [
    LocationMapComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    LocationMapComponent
  ]
})
export class LocationMapModule {
  static forRoot(): ModuleWithProviders<LocationMapModule> {
    return {
      ngModule: LocationMapModule,
      providers: [
        LocationMapService
      ]
    };
  }
}
