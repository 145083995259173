import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { AdminUserRole, UserRolType } from './types/user-role';
import { ApolloErrorHandlerService, ApolloErrorHandlerType, HandleError } from 'tauns-common';
import { GetUserRolesResponse, GetUserRolesQuery, GetUserRoleQuery, GetUserRoleResponse, MyPermissionsQuery, MyPermissionsResponse } from './gql/user-role';
import { catchError, map } from 'rxjs/operators';
import { Permission } from './types/permission';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  private handleError: HandleError;

  constructor(
    private apollo: Apollo,
    private apolloErrorHandlerService: ApolloErrorHandlerService,
  ) {
    this.handleError = this.apolloErrorHandlerService.createHandleError('UserRoleService');
  }

  getUserRoles(userType: UserRolType): Observable<AdminUserRole[]> {
    const variables: any = {
      filter: {
        userType: { eq: userType },
      }
    };
    return this.apollo.query<GetUserRolesResponse>({
      query: GetUserRolesQuery,
      variables,
    }).pipe(
      map(({ data }) => data?.userRoles),
      catchError(this.handleError<AdminUserRole[]>(
        'getUserRoles', [], ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  getUserRole(id: string): Observable<AdminUserRole | null> {
    return this.apollo.query<GetUserRoleResponse>({
      query: GetUserRoleQuery,
      variables: {
        id
      },
    }).pipe(
      map(({ data }) => data?.userRole),
      catchError(this.handleError<null>(
        'getUserRole', null, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  myPermissions(): Observable<Permission[] | null | undefined> {
    return this.apollo.query<MyPermissionsResponse>({
      query: MyPermissionsQuery,
    }).pipe(
      map(({ data }) => data?.myPermissions?.permissions),
      catchError(this.handleError<null>(
        'myPermissions', null, ApolloErrorHandlerType.DISCREET
      ))
    );
  }
}
