import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DynamicDialogService } from '../dynamic-dialog.service';

// import { DynamicDialogComponent } from './dynamic-dialog/dynamic-dialog.component';

@NgModule({
    // declarations: [DynamicDialogComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatDialogModule
    ],
    providers: [
        DynamicDialogService
    ]
})
export class DynamicDialogTestingModule {}
