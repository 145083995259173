import {
  Component, AfterViewInit, OnChanges, ViewChild, Input, Output,
  EventEmitter, ChangeDetectorRef, ViewEncapsulation, SimpleChanges, SimpleChange
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { Observable } from 'rxjs';
import { merge, of as observableOf, from } from 'rxjs';
import { catchError, map, startWith, switchMap, debounceTime } from 'rxjs/operators';

import { DynamicTableOptions, RequestTableOptions, SourceFunction } from '../dynamic-table-classes';

export interface SelectedItem {
  itemId: any;
  idField: string;
}

@Component({
  selector: 'deva-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicTableComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  @Input() source: undefined | unknown[] | SourceFunction = [];
  @Input() options: DynamicTableOptions | undefined = undefined;

  @Input() reset: boolean = false;

  @Input() idField: string = 'id';
  selectedItemsState?: any[] | null;
  @Input() selectedItems?: any[] | null;

  //// :::::::: EVENTS CONTROL ::::::::: ////
  @Output() addItem: EventEmitter<true> = new EventEmitter<true>();
  @Output() viewItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedItemsChange = new EventEmitter<any[] | null>();

  //// :::::::: PRIVATE ::::::::: ////
  searchFormControl: UntypedFormControl = new UntypedFormControl();
  isSearching: boolean = false;
  filterFormControl: UntypedFormControl = new UntypedFormControl();

  dataSource = new MatTableDataSource<any>();
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  isSourceFunction: boolean = false;

  displayedColumns: string[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isError = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    // console.log('table ngOnInit');
    if (this.sort) {
      merge(this.sort.sortChange, this.searchFormControl.valueChanges)
        .subscribe(() => this.paginator ? this.paginator.pageIndex = 0 : null);
    }
    this.subscribeMerge();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('table ngOnChanges', changes);

    const options = changes['options'] ? (changes['options'] as SimpleChange).currentValue : null;

    if (options) {
      this.displayedColumns = [];
      if (options.columns) {
        this.displayedColumns = this.displayedColumns.concat(options.columns.map((value: any) => value.key));
      }

      if (options.viewButton) {
        this.displayedColumns.splice(this.displayedColumns.length, 0, 'view');
      }
      if (options.deleteButton) {
        this.displayedColumns.splice(this.displayedColumns.length, 0, 'delete');
      }
      if (options.filters) {
        if (options.filters.length > 0) {
          this.filterFormControl.setValue(0);
        }
      }
      if (options.checkBox) {
        this.displayedColumns.unshift('checkBox');
        this.subscribeSelection();
      }
    }
    const source = changes['source'] ? (changes['source'] as SimpleChange).currentValue : null;
    if (source && this.source) {
      if (this.source instanceof Array) {
        this.isSourceFunction = false;
        // console.log('options.source instanceof Array');
        this.resultsLength = this.source.length;
        this.dataSource.data = this.source;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.changeDetectorRef.detectChanges();
      } else {
        this.isSourceFunction = true;
        if (this.options && !this.options.filters) {
          this.source({ page: 0, pageSize: 5 }).subscribe(data => {
            this.dataSource.data = data.items;
            this.resultsLength = data.totalCount;
          });
        }
      }
      this.validateSelection();
    }

    const reset = changes['reset'] ? (changes['reset'] as SimpleChange).currentValue : null;
    if (reset) {
      this.reloadData();
    }
    const selection = changes['selection'] ? (changes['selection'] as SimpleChange).currentValue : null;
    if (selection) {
      if (!(selection instanceof SelectionModel)) {
        throw new Error('Selection input must be an SelectionModel');
      }
    }
    this.selectedItemsState = changes['selectedItems']
      ? (changes['selectedItems'] as SimpleChange).currentValue
      : null;
    this.validateSelection();
  }

  subscribeMerge() {
    // console.log('subscribeMerge');
    const searchPipe = this.searchFormControl.valueChanges.pipe(debounceTime(400));
    if (this.sort && this.paginator)
    merge(
      this.sort.sortChange,
      this.paginator.page,
      searchPipe,
      this.filterFormControl.valueChanges
    ).pipe(
      startWith({}),
      switchMap((item) => {
        this.isLoadingResults = true;
        // console.log('sort pag detacted',item,!!this.sourceFunction);
        // const filterField = this.getFilterField(this.filterFormControl.value);
        const filter = this.options && this.options.filters ? this.options.filters[this.filterFormControl.value ? this.filterFormControl.value : 0] : undefined;
        // console.log('filterField',filterField,this.filterFormControl.value);
        // console.log('this.sourceFunction',this.sourceFunction);
        const srcRes: RequestTableOptions = {
          page: this.paginator ? this.paginator.pageIndex : 0,
          pageSize: this.paginator ? this.paginator.pageSize : 5,
          query: this.searchFormControl.value ? this.searchFormControl.value : null,
          // filter: filterField ? { field: filterField, value: this.filterFormControl.value } : null,
          filter: filter ? [filter] : [],
        };
        if (this.sort) {
          srcRes.sort = this.sort.active;
          srcRes.order = this.sort.active;
        }
        return this.source instanceof Array ?
          from([{
            items: this.source ? this.source : [],
            totalCount: this.resultsLength ? this.resultsLength : 0
          }]) :
          this.source ? this.source(srcRes) : from([{ items: [], totalCount: 0 }]);
      }),
      map((data) => {
        // console.log('tableMapFunction',data);
        this.isLoadingResults = false;
        this.isError = false;
        this.resultsLength = data.totalCount;
        return data.items;
      }),
      catchError((error) => {
        // console.log('tableError - ',error);
        this.isLoadingResults = false;
        this.isError = true;
        return observableOf([]);
      })
    ).subscribe(data => {
      // console.log('subscribeMerge source data',data);w
      if (this.source instanceof Array) {
        this.dataSource.data = this.source;
        if (this.filterFormControl.value) {
          this.dataSource.filter = this.filterFormControl.value;
        }
        if (this.searchFormControl.value) {
          this.dataSource.filter = this.searchFormControl.value;
        }
      } else {
        this.dataSource.data = data;
      }
      this.validateSelection();
      this.changeDetectorRef.detectChanges();
    });
  }

  reloadData() {
    if (!(this.source instanceof Array)) {
      this.searchFormControl.reset();
      // const filterField = this.getFilterField(this.filterFormControl.value);
      const filter = this.options && this.options.filters ? this.options.filters[this.filterFormControl.value ? this.filterFormControl.value : 0] : undefined;
      const srcRes: RequestTableOptions = {
        page: this.paginator ? this.paginator.pageIndex : 0,
        pageSize: this.paginator ? this.paginator.pageSize : 5,
        query: this.searchFormControl.value ? this.searchFormControl.value : null,
        // filter: filterField ? { field: filterField, value: this.filterFormControl.value } : null,
        filter: filter ? [filter] : [],
      };
      if (this.sort) {
        srcRes.sort = this.sort.active;
        srcRes.order = this.sort.active;
      }
      if (this.source) {
        this.source(srcRes).subscribe(data => {
          console.log('reloadData source data',data);
          this.isLoadingResults = false;
          this.isError = false;
          this.resultsLength = data.totalCount;
          this.dataSource.data = data.items;
          this.validateSelection();
        });
      }
    }
  }

  // Checkbox functions
  validateSelection() {
    if (this.options?.checkBox) {
      if (this.dataSource?.data.length) {
        this.dataSource.data.forEach(row => {
          if (this.selectedItemsState != null) {
            if (this.selectedItemsState.length) {
              const item = this.selectedItemsState.find(selectedItem => row[this.idField] === selectedItem);
              if (item) {
                this.selection.select(row);
              }
            } else {
              this.selection.select(row);
            }
          }
        });

      }
    }
  }

  isAllSelected() {
    return this.selectedItemsState?.length === 0;
  }

  masterToggle() {
    if(this.isAllSelected()) {
      this.selection.clear();
      this.selectedItemsState = null;
    } else {
      this.selectedItemsState = [];
      this.dataSource.data.forEach(row => this.selection.select(row));
    }
    this.selectedItemsChange.emit(this.selectedItemsState);
  }

  itemToggle(item: any) {
    console.log('itemToggle item', item);
    this.selection.toggle(item);
    if (this.selectedItemsState == null) {
      this.selectedItemsState = [item[this.idField]];
    } else {
      if (this.selectedItemsState.length === 0) {
        this.selection.clear();
        this.selectedItemsState = null;
      } else {
        const idx = this.selectedItemsState.indexOf(item[this.idField]);
        console.log('itemToggle idx', idx);
        if (idx === -1) {
          this.selectedItemsState.push(item[this.idField])
        } else {
          this.selectedItemsState.splice(idx, 1);
          if (this.selectedItemsState.length === 0) {
            this.selectedItemsState = null;
          }
        }
      }
    }
    console.log('itemToggle this.selectedItemsState', this.selectedItemsState);
    this.selectedItemsChange.emit(this.selectedItemsState);
  }

  subscribeSelection() {
    // this.selection.changed.subscribe(values => {
    //   // console.log('this.selection.selected',this.selection.selected);
    //   if (!this.isAllSelected()) {
    //     this.selectedItemsChange.emit(this.selection.selected);
    //   }
    // });
  }

  getFilterField(filterValue: any) {
    // console.log('getFilterField',filterValue);
    if (!filterValue) {
      return null;
    }
    const filterD = this.options && this.options.filters ? this.options.filters.find(flt => flt.value === filterValue) : null;
    // console.log('filter',filter);
    return filterD ? filterD.field : null;
  }
}
