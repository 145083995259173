import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';

import { FormlyMatGMPlacesComponent } from './ngx-formly-material-gmplaces.component';
import { GMPlacesModule } from './gmplaces/gmplaces.module';



@NgModule({
  declarations: [
    FormlyMatGMPlacesComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormlyMatFormFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'gmplaces',
          component: FormlyMatGMPlacesComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    GMPlacesModule.forRoot()
  ],
  exports: [
    FormlyMatGMPlacesComponent
  ]
})
export class FormlyMatGMPlacesModule { }
