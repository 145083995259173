import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material/form-field';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

@Component({
  selector: 'app-ngx-formly-material-time',
  templateUrl: './ngx-formly-material-time.component.html',
  styleUrls: ['./ngx-formly-material-time.component.scss']
})
export class FormlyMatTimeComponent<F extends FormlyFieldConfig<FormlyFieldProps>> extends FieldType<F> implements OnInit {
  get timeFormControl(): UntypedFormControl {
    if (this.formControl) {
      return this.formControl as UntypedFormControl;
    } else {
      return new UntypedFormControl(0);
    }
  }
  // placeholder: string = '';
  override defaultOptions: any = {
    templateOptions: {
      floatLabel: 'always'
    },
  };
  constructor() {
    super();
    
  }

  ngOnInit(): void {
    // if (typeof this.field?.templateOptions?.label === 'string') {
    //   this.placeholder = this.field?.templateOptions?.label;
    // }
  }

}
