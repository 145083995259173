import gql from 'graphql-tag';
export interface RequestPasswordData {
    email: string;
}

export const RequestPasswordQuery = (isOperator?: boolean) => {
    return gql`
        query RequestPassword($email: String!) {
          requestPassword (email: $email${isOperator ? ', isOperator: true' : ''}) {
            success
          }
        }`;
}

export interface RequestPasswordResponse {
  requestPassword: {
      success: boolean;
  };
}