import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { FormlyMatTimeModule } from '../ngx-formly-material-time';
import { NgxFormlyMaterialArrayModule } from '../ngx-formly-material-array';
import { FormlyMatFileModule } from '../ngx-formly-material-file';
import { FormlyMatLocationModule } from '../ngx-formly-material-location';

import { SubItemDialogComponent } from './sub-item-dialog.component';
import { SubItemDialogService } from './sub-item-dialog.service';
import { FormlyMaterialModule } from '@ngx-formly/material';

@NgModule({
  declarations: [SubItemDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    FormlyModule.forChild(),
    FormlyMaterialModule,
    FormlyMatToggleModule,
    FormlyMatTimeModule,
    NgxFormlyMaterialArrayModule,
    FormlyMatFileModule,
    FormlyMatLocationModule
  ],
  providers: [
    SubItemDialogService,
  ],
  exports: [
    SubItemDialogComponent,
  ]
})
export class SubItemDialogModule {}
