import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { DynamicDialogComponent } from './dynamic-dialog/dynamic-dialog.component';
import { DIALOG_CONFIG, DynamicDialogConfig } from './dynamic-dialog.config';
import { DynamicDialogService } from './dynamic-dialog.service';


@NgModule({
  declarations: [DynamicDialogComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [DynamicDialogComponent]
})
export class DynamicDialogModule {
  static forRoot(conf: DynamicDialogConfig): ModuleWithProviders<DynamicDialogModule> {
    return {
      ngModule: DynamicDialogModule,
      providers: [
        DynamicDialogService,
        { provide: DIALOG_CONFIG, useValue: conf }
      ]
    };
  }
}
