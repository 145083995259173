import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';

import { FormlyMatReadOnlyComponent } from './ngx-formly-material-read-only.component';

@NgModule({
  declarations: [
    FormlyMatReadOnlyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'read-only',
          component: FormlyMatReadOnlyComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    FormlyMatFormFieldModule
  ],
  exports: [
    FormlyMatReadOnlyComponent
  ]
})
export class NgxFormlyMatReadOnlyModule { }
