import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloClientOptions, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';

import { AppConfig } from './app.config';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    // errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    // errorPolicy: 'all',
  },
};

const uri = AppConfig.apiURL + AppConfig.gqlEndpoint; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({ uri, withCredentials: true }),
    cache: new InMemoryCache({ resultCaching: false, addTypename: false }),
    credentials: 'include',
    defaultOptions
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
  exports: [ApolloModule]
})
export class GraphQLModule { }
