import { NgModule } from '@angular/core';
import { TaunsCommonComponent } from './tauns-common.component';



@NgModule({
  declarations: [
    TaunsCommonComponent
  ],
  imports: [
  ],
  exports: [
    TaunsCommonComponent
  ]
})
export class TaunsCommonModule { }
