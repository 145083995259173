import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TimezoneOffsetInterceptor implements HttpInterceptor {
  constructor() {}
  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'JSONP') {
      return next.handle(request);
    }
    const authReq = request.clone({ setHeaders: { "X-Timezone-Offset": this.getTimezoneOffset() } });
    return next.handle(authReq);
  }
  
  // ref: https://www.bennadel.com/blog/3588-reporting-the-users-timezone-offset-to-the-server-using-an-api-client-in-angular-7-2-10.htm
  private getTimezoneOffset(): string {
    return (String(new Date().getTimezoneOffset()));
  }
}
