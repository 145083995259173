import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldGroupTypeConfig, FieldType, FormlyFieldConfig, FormlyTemplateOptions } from "@ngx-formly/core";

export interface FormlyGroupFieldConfig extends FormlyFieldConfig {
  templateOptions?: FormlyTemplateOptions & { arrayAppearance?: 'flex' | 'accordion' };
}

@Component({
  selector: 'ngx-formly-material-array',
  template: `
    <formly-field *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
    <ng-content></ng-content>
  `,
  styles: [
  ],
  host: {
    '[class]': 'field.fieldGroupClassName || ""',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxFormlyMaterialGroupComponent extends FieldType<FieldGroupTypeConfig> {}
