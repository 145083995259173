import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogService } from '../../../components/dynamic-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AuthService } from '../../services/auth.service';
import { AppConfig } from 'projects/tauns-admin/src/app/app.config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  authForm = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  authFields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      // defaultValue: 'admin@mail.com',
      validators: {
        validation: ['email']
      },
      className: 'col-xs-1-1',
      templateOptions: {
        label: 'Correo',
        placeholder: 'Correo',
        required: true,
        type: 'email',
      }
    },
    {
      key: 'password',
      // defaultValue: '123456',
      type: 'input',
      className: 'col-xs-1-1',
      templateOptions: {
        label: 'Contraseña',
        placeholder: 'Contraseña',
        required: true,
        type: 'password'
      }
    }
  ];
  siteKey = AppConfig.reCaptchaKey;
  lang = 'es-419';
  validCaptcha = false;

  isOperator: boolean = false;
  constructor(
    private dynamicDialogService: DynamicDialogService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    console.log('siteKey', this.siteKey);
  }

  ngOnInit(): void {
    this.authForm = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    this.authService.logout().subscribe({
      next: (res) => console.debug('logued out res', res),
      error: (error) => console.debug('logued out error', error),
    });
    this.isOperator = this.authService.validateIsOperator(this.router.url);
  }

  handleSuccess() {
    this.validCaptcha = true;
  }

  handleExpire() {
    this.validCaptcha = false;
  }

  onSubmit() {
    let { email, password } = this.authForm.value;
    this.dynamicDialogService.showLoadingDialog();
    this.authService.authLogin({ email, password }, this.isOperator).subscribe(async res => {
      console.log(res);
      if (res) {
        await this.router.navigate(this.isOperator ? ['/operator'] : ['/admin']);
      }
      this.dynamicDialogService.hideLoadingDialog();
    });
  }

}
