import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GoogleMapsApiService } from '../../google-maps-api/google-maps-api.service';

@Injectable()
export class LocationMapService {
  apiLoaded: Observable<boolean>
  constructor(
    private googleMapsApiService: GoogleMapsApiService
  ) {
    console.log('LocationMapService constructor');
    this.apiLoaded = this.googleMapsApiService.$apiLoaded;
  }
}
