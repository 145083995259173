import gql from 'graphql-tag';

export interface AdminRefreshTokenResponse {
  refreshAdmin: {
    success: boolean;
    token: string;
  }
}

export const RefreshTokenQuery = (isOperator?: boolean) => {
  return gql`
  query RefreshToken {
    refreshAdmin${isOperator ? '(isOperator: true)' : ''} {
      success
      token
    }
  }`;
};

export interface SuperAdminRefreshTokenResponse {
  refreshSuperAdmin: {
    success: boolean;
    token?: string;
  }
}

export const SuperAdminRefreshTokenQuery = gql`
query RefreshSuperAdmin {
  refreshSuperAdmin {
    success
    token
  }
}
`; 