import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import jwtDecode from 'jwt-decode';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApolloErrorHandlerService, HandleError, ApolloErrorHandlerType } from '../../core/services/apollo-error-handler.service';

import { LoginData, LoginMutation, AdminLoginResponse, SuperAdminLoginResponse, LoginSuperAdminMutation, LoginDataSuperAdmin } from './gql/login';
import { RefreshTokenQuery, AdminRefreshTokenResponse, SuperAdminRefreshTokenResponse, SuperAdminRefreshTokenQuery } from './gql/refresh';
import { LogoutQuery, AdminLogoutResponse, LogoutSuperAdminQuery, SuperAdminLogoutResponse } from './gql/logout';
import { RequestPasswordData, RequestPasswordQuery, RequestPasswordResponse } from './gql/request';
import { ResetPasswordData, ResetPasswordQuery, ResetPasswordResponse } from './gql/reset';
import { AppConfig } from 'projects/tauns-admin/src/app/app.config';
import { WindowRefService } from 'projects/tauns-admin/src/app/core/services/window-ref.service';
import { UserRoleService } from '../../admin/services/user-role.service';

export enum UserType {
  OPERATOR = 'operator',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super-admin',
}

export enum OperatorRol {
  access = 'access',
  incidents = 'incidents',
  payments = 'payments',
}

export interface DecodedToken {
  _id: string;
  exp: number;
  iat: number;
  name: string;
  shortId: string;
  userType: UserType;
  roles?: OperatorRol[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private handleError: HandleError;

  token?: string;
  isOperator?: boolean;

  private timeout: any;

  private isAuthenticatedSubject: ReplaySubject<boolean> = new ReplaySubject(1);
  get isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }

  private windowRef: Window;

  constructor(
    private apollo: Apollo,
    private apolloErrorHandlerService: ApolloErrorHandlerService,
    private windowRefService: WindowRefService,
    private userRoleService: UserRoleService,
  ) {
    this.handleError = this.apolloErrorHandlerService.createHandleError('AuthService');
    this.windowRef = this.windowRefService.nativeWindow;
  }

  init(userType: UserType): Observable<boolean> {
    // console.log('init', userType);
    if (userType === UserType.OPERATOR || userType === UserType.ADMIN) {
      const isOperator = userType === UserType.OPERATOR;
      return this.getTokenFromRefresh(isOperator);
    } else {
      return this.getSuperAdminTokenFromRefresh();
    }
  }

  authLogin(data: LoginData, isOperator?: boolean): Observable<boolean> {
    // console.log('authLogin data', data);
    // console.log('authLogin isOperator', isOperator);
    return this.apollo.mutate<AdminLoginResponse>({
      mutation: LoginMutation(isOperator),
      variables: data
    }).pipe(
      map(({ data }) => {
        let res;
        if (data?.loginAdmin) {
          res = data?.loginAdmin;
        }
        // console.log('authLogin res', res);
        this.processToken(res?.token);
        if (res?.token) {
          this.isOperator = isOperator;
        }
        return !!res?.success;
      }),
      catchError(this.handleError<boolean>(
        'authLogin', false, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  authLoginSuperAdmin(data: LoginDataSuperAdmin): Observable<boolean> {
    return this.apollo.mutate<SuperAdminLoginResponse>({
      mutation: LoginSuperAdminMutation,
      variables: data
    }).pipe(
      map(({ data }) => {
        let res;
        if (data?.loginSuperAdmin) {
          res = data?.loginSuperAdmin;
        }
        this.processSuperAdminToken(res?.token);
        return !!res?.success;
      }),
      catchError(this.handleError<boolean>(
        'authLoginSuperAdmin', false, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  startSilentRefresh(token: string) {
    const decoded: DecodedToken = jwtDecode(token);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.getTokenFromRefresh(this.isOperator).subscribe(success => {
        if (success && this.token) {
          // console.log('getTokenFromRefresh success');
        }
      });
      this.timeout = null;
    }, (decoded.exp * 1000) - (Date.now() + (5 * 1000)));
  }

  startSuperAdminSilentRefresh(token: string) {
    const decoded: DecodedToken = jwtDecode(token);
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.getSuperAdminTokenFromRefresh().subscribe(success => {
        if (success && this.token) {
          // console.log('getTokenFromRefresh success');
        }
      });
      this.timeout = null;
    }, (decoded.exp * 1000) - (Date.now() + (5 * 1000)));
  }

  getTokenFromRefresh(isOperator?: boolean) {
    // console.log('getTokenFromRefresh isOperator', isOperator);
    return this.apollo.query<AdminRefreshTokenResponse>({
      query: RefreshTokenQuery(isOperator)
    }).pipe(
      map(({ data }) => {
        const res = data?.refreshAdmin;
        // console.log('getTokenFromRefresh res', res);
        this.processToken(res?.token);
        if (res?.token) {
          this.isOperator = isOperator;
        }
        return res?.success;
      }),
      catchError(this.handleError<boolean>(
        'getTokenFromRefresh', false, ApolloErrorHandlerType.SILENCE
      ))
    );
  }

  getSuperAdminTokenFromRefresh() {
    // console.log('getSuperAdminTokenFromRefresh');
    return this.apollo.query<SuperAdminRefreshTokenResponse>({
      query: SuperAdminRefreshTokenQuery
    }).pipe(
      map(({ data }) => {
        const res = data?.refreshSuperAdmin;
        // console.log('getSuperAdminTokenFromRefresh res', res);
        this.processSuperAdminToken(res?.token);
        return res?.success;
      }),
      catchError(this.handleError<boolean>(
        'getSuperAdminTokenFromRefresh', false, ApolloErrorHandlerType.SILENCE
      ))
    );
  }


  processToken(token: string | undefined) {
    this.token = token;
    if (this.token) {
      this.startSilentRefresh(this.token);
    }
    this.isAuthenticatedSubject.next(!!token);
  }

  processSuperAdminToken(token: string | undefined) {
    this.token = token;
    if (this.token) {
      this.startSuperAdminSilentRefresh(this.token);
    }
    this.isAuthenticatedSubject.next(!!token);
  }

  logout() {
    return this.apollo.query<AdminLogoutResponse>({
      query: LogoutQuery,
    }).pipe(
      map(({ data }) => {
        const res = data?.logoutAdmin;
        // console.log('logout res', res);
        if (res?.success) {
          this.processToken(undefined);
          this.isOperator = false;
        }
        return !!res?.success;
      }),
      catchError(this.handleError<boolean>(
        'logout', false, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  logoutSuperAdmin() {
    return this.apollo.query<SuperAdminLogoutResponse>({
      query: LogoutSuperAdminQuery,
    }).pipe(
      map(({ data }) => {
        const res = data?.logoutSuperAdmin;
        // console.log('logout res', res);
        if (res?.success) {
          this.processToken(undefined);
          this.isOperator = false;
        }
        return !!res?.success;
      }),
      catchError(this.handleError<boolean>(
        'logout', false, ApolloErrorHandlerType.DISCREET
      ))
    );
  }

  requestPassword(data: RequestPasswordData, isOperator?: boolean): Observable<boolean> {
    // console.log('requestPassword', data);
    return this.apollo.query <RequestPasswordResponse>({
      query: RequestPasswordQuery(isOperator),
      variables: data
    }).pipe(
      map(({data}) => {
        // console.log('requestPassword data', data);
        return data.requestPassword.success;
      }),
      catchError(this.handleError<boolean>('requestPassword', false, ApolloErrorHandlerType.DISCREET))
    );
  }

  resetPassword(data: ResetPasswordData, isOperator?: boolean): Observable<boolean> {
    // console.log('resetPassword', data);
    return this.apollo.query <ResetPasswordResponse>({
      query: ResetPasswordQuery(isOperator),
      variables: data
    }).pipe(
      map(({data}) => {
        // console.log('resetPassword data', data);
        return data.resetPassword.success;
      }),
      catchError(this.handleError<boolean>('resetPassword', false, ApolloErrorHandlerType.DISCREET))
    );
  }

  validateIsOperator(url: string): boolean {
    // console.log('validateIsOperator', url);
    const location = this.windowRef.location.toString();
    // console.log('validateIsOperator location', location);
    const match = location.match(AppConfig.operatorDomainPrefix);
    // console.log('validateUserType match', !!match?.length);
    return !!match?.length;
  }

  validateUserType(): UserType {
    // console.log('validateUserType');
    const location = this.windowRef.location.toString();
    // console.log('validateUserType location', location);
    // console.log('validateUserType AppConfig.operatorDomainPrefix', AppConfig.operatorDomainPrefix);
    if (location.match(AppConfig.operatorDomainPrefix)) {
      return UserType.OPERATOR;
    }
    // console.log('validateUserType AppConfig.operatorDomainPrefix', AppConfig.operatorDomainPrefix);
    return UserType.ADMIN;
  }

  getDecodedToken(): DecodedToken | undefined{
    if (!this.token) {
      return undefined;
    }
    return jwtDecode(this.token) as DecodedToken;
  }

  validatePermissions(): void  {
    this.userRoleService.myPermissions().subscribe({
      next: (permissions) => {
        // console.log('validatePermissions myPermissions', permissions);
      }
    });
  }
}
