import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, HostBinding, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { GMPlacesService } from './gmplaces.service';

export function createValueValidator() {
  return (c: UntypedFormControl) => {
    return null;
  };
}

@Component({
  selector: 'app-gmplaces',
  templateUrl: './gmplaces.component.html',
  styleUrls: ['./gmplaces.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: GMPlacesComponent },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GMPlacesComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => GMPlacesComponent), multi: true },
  ]
})
export class GMPlacesComponent implements OnChanges, MatFormFieldControl<string>, OnDestroy, ControlValueAccessor {
  static nextId = 0;
  
  @HostBinding('id')
  id = `app-gmplaces-${GMPlacesComponent.nextId++}`;
  @HostBinding('attr.aria-describedby')
  describedBy = '';
  @HostBinding('class.example-floating')
  shouldLabelFloat = true;

  @ViewChild(MatAutocomplete) matAutocomplete?: MatAutocomplete;

  private placeholderPriv: string = '';
  @Input()
  get placeholder(): string { return this.placeholderPriv; }
  set placeholder(value: string) {
    this.placeholderPriv = value;
    this.stateChanges.next();
  }

  private requiredPriv = false;
  @Input()
  get required(): boolean { return this.requiredPriv; }
  set required(value: boolean) {
    this.requiredPriv = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private disabledPriv = false;
  @Input()
  get disabled(): boolean { return this.disabledPriv; }
  set disabled(value: boolean) {
    this.disabledPriv = coerceBooleanProperty(value);
    this.disabledPriv ? this.valueFormControl.disable() : this.valueFormControl.enable();
    this.stateChanges.next();
  }

  @Input()
  set value(value: string) {
    this.valueFormControl.setValue(value);
    this.stateChanges.next();
  }
  get value(): string {
    return this.valueFormControl.value;
  }

  valueFormControl: UntypedFormControl = new UntypedFormControl();
  stateChanges = new Subject<void>();
  focused = false;
  ngControl = null;
  errorState = false;
  controlType = 'app-gmplaces';

  get empty() {
    const value = this.valueFormControl.value;
    return !value.longitude && !value.latitude;
  }

  places?: google.maps.places.AutocompletePrediction[];
  loadingPlaces: boolean = false;
  
  propagateChange: any = () => { };
  propagateTouched: any = () => { };
  validateFn: any = () => { };

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private gmplacesService: GMPlacesService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    fm.monitor(elRef, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.valueFormControl.valueChanges.pipe(
      tap(value => this.loadingPlaces = value?.length >= 3),
      debounceTime(600),
      switchMap(value => {
        if (value) {
          if (typeof value !== 'object') {
            return this.gmplacesService.autocompleteAddress(value as string);
          } else {
            const place = (value as any).place_id;
            if (place) {
              return this.gmplacesService.getPlaceDetails(place).pipe(
                map(placeDetails => {
                  // console.log('placeDetails', placeDetails);
                  // console.log('propagateChange', this.propagateChange);
                  if (placeDetails && this.propagateChange) {
                    this.propagateChange(placeDetails);
                  }
                  this.changeDetectorRef.detectChanges();
                  return [];
                })
              );
            }
          }
        }
        return of([]);
      })
    ).subscribe((places: any[] | null) => {
      // console.log('autocompleteAddress places', places);
      this.loadingPlaces = false;
      this.places = places || [];
    });
    this.gmplacesService.apiLoaded.subscribe(loaded => this.disabled = !loaded ?? this.disabled);
  }

  ngOnInit(): void {
  }

  ngOnChanges(inputs: any) {
    // console.log('ngOnChanges', inputs);
    this.validateFn = createValueValidator();
  }

  
  writeValue(value: string) {
    // console.log('writeValue',value);
    if (value) {
      this.valueFormControl.setValue(value);
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }


  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
  
  onContainerClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.propagateTouched();
  }


  autoCompleteDisplayWith(value: any) { return value?.description ? value.description : value; }
  

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
  }
}
