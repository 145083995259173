import gql from 'graphql-tag';
import { AdminUserRole } from "../types/user-role";
import { Permission } from '../types/permission';

export interface GetUserRolesResponse {
  userRoles: AdminUserRole[];
}

export const GetUserRolesQuery = gql`
query GetUserRoles($filter: UserRoleFilter!) {
  userRoles(filter: $filter){
    _id
    name
    userType
  }
}
`;

export interface GetUserRoleResponse {
  userRole: AdminUserRole;
}

export const GetUserRoleQuery = gql`
query GetUserRole($id: String!) {
  userRole(id: $id){
    _id
    name
    userType
  }
}
`;

export interface MyPermissionsResponse {
  myPermissions: {
    permissions?: Permission[];
  }
}

export const MyPermissionsQuery = gql`
query MyPermissions {
  myPermissions {
    permissions {
      _id
      action
      subject
      createdAt
      updatedAt
    }
  }
}
`;