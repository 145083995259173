import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  blacklist: string[] = [
    'loginAdmin',
    'refreshAdmin',
    'logoutAdmin',
    'requestPassword',
    'resetPassword',
    'loginSuperAdmin',
    'refreshSuperAdmin',
    'logoutSuperAdmin',
  ];
  constructor(
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // console.debug('intercept', request.method);
    // console.debug('intercept', request.url);
    // console.debug('intercept', request.body);
    if (this.requestBlacklist(request)) {
      return next.handle(request);
    }
    return this.authService.isAuthenticated.pipe(
      take(1),
      switchMap((isAuth: boolean) => {
        // console.debug('intercept isAuth', isAuth);
        if (isAuth) {
          const authToken = this.authService.token;
          // console.debug('intercept authToken', authToken);
          request = request.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } });
        }
        // console.debug('intercept request', request.headers);
        return next.handle(request);
      })
    )
  }

  requestBlacklist(request: HttpRequest<unknown>): boolean {
    if (request.method === 'PUT') {
      return true;
    }
    if (request.method === 'JSONP') {
      return true;
    }
    if (typeof request.body === 'object') {
      const requestTestPart = JSON.stringify(request.body || {});
      return !!this.blacklist.find((b) => requestTestPart.match(b));
    }
    return false;
  }
}
