import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DialogButton, DynamicDialogComponent } from './dynamic-dialog/dynamic-dialog.component';
export { DialogButton };

@Injectable()
export class DynamicDialogService {
  loadingDialogRef: MatDialogRef<DynamicDialogComponent> | null = null;

  constructor(
    private matDialog: MatDialog
  ) { }

  showLoadingDialog(title?: string): MatDialogRef<DynamicDialogComponent> {
    if (!this.loadingDialogRef) {
      this.loadingDialogRef = this.matDialog.open(DynamicDialogComponent, {
        disableClose: true,
        data: {
          dialogType: 'loading',
          messageTitle: title ? title : 'Loading...'
        }
      });
    }
    return this.loadingDialogRef;
  }

  hideLoadingDialog() {
    if (this.loadingDialogRef) {
      this.loadingDialogRef.close();
      this.loadingDialogRef = null;
    }
  }

  showErrorDialog(errorMessage: string, title?: string): MatDialogRef<DynamicDialogComponent> {
    this.hideLoadingDialog();
    return this.matDialog.open(DynamicDialogComponent, {
      disableClose: true,
      data: {
        dialogType: 'error',
        message: errorMessage,
        messageTitle: title ? title : 'Error'
      }
    });
  }

  showMessageDialog(title: string, message: string, buttons?: DialogButton[]): MatDialogRef<DynamicDialogComponent> {
    this.hideLoadingDialog();
    return this.matDialog.open(DynamicDialogComponent, {
      disableClose: true,
      data: {
        dialogType: 'message',
        message,
        messageTitle: title,
        buttons
      }
    });
  }
}
