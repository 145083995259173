import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormlyModule } from '@ngx-formly/core';
import { FileInputComponent } from './file-input/file-input.component';
import { FileSizePipe } from './file-size/file-size.pipe';
import { FileTypeConfig, FILE_TYPE_CONFIG } from './file-type-config';
import { FormlyMatFileComponent } from './ngx-formly-material-file/ngx-formly-material-file.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileInputUploadComponent } from './file-input-upload/file-input-upload.component';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';

// import { FileUploadService } from './file-upload.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatTooltipModule,
    ClipboardModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'file',
          component: FormlyMatFileComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    FormlyMatFormFieldModule,
    MatFormFieldModule
  ],
  declarations: [
    FileUploadComponent,
    FileSizePipe,
    FileInputComponent,
    FileInputUploadComponent,
    FormlyMatFileComponent,
  ],
  exports: [
    FileInputUploadComponent
  ]
})
export class FormlyMatFileModule {

  constructor(
    // @Optional() @SkipSelf() parentModule: FormlyMatFileModule,
  ) {
    // if (parentModule) {
    //   throw new Error(
    //     'FormlyMatFileModule is already loaded. Import it in the AppModule only');
    // }
  }

  static forRoot(config: FileTypeConfig = {}): ModuleWithProviders<FormlyMatFileModule> {
    const actualConfig: FileTypeConfig = {
      dropzoneText: config.dropzoneText ? config.dropzoneText : 'drag and drop a file here or',
      browseFilesButtonText: config.browseFilesButtonText ? config.browseFilesButtonText : 'browse files',
      uploadFileTooltip: config.uploadFileTooltip ? config.uploadFileTooltip : 'upload file',
      removeFileTooltip: config.removeFileTooltip ? config.removeFileTooltip : 'remove file'
    };

    return {
      ngModule: FormlyMatFileModule,
      providers: [
        { provide: FILE_TYPE_CONFIG, useValue: actualConfig },
        // FileUploadService
      ]
    };
  }

}
