import gql from 'graphql-tag';
export interface ResetPasswordData {
    token: string;
    password: string;
}

export const ResetPasswordQuery = (isOperator?: boolean) => {
  return gql`
    query ResetPassword($token: String!, $password: String!) {
      resetPassword (token: $token, password: $password${isOperator ? ', isOperator: true' : ''}) {
        success
      }
    }`;
}

export interface ResetPasswordResponse {
  resetPassword: {
      success: boolean;
  };
}