<div class="authContainer">
    <div class="authCard">
        <div class="formContainer">
            <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
                <p>Ingresa tu nueva contraseña.</p>
                <formly-form [form]="authForm" [fields]="authFields"></formly-form>
                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (expire)="handleExpire()" (success)="handleSuccess()"
                    [useGlobalDomain]="false" size="normal" [hl]="lang" theme="light" type="image"
                    formControlName="recaptcha">
                </ngx-recaptcha2>
                <button mat-raised-button type="submit" color="primary" class="loginBtn"
                    [disabled]="authForm.invalid || !validCaptcha">Recuperar</button>
                <a mat-button [routerLink]="['/auth/login']">Regresar</a>
            </form>
        </div>
    </div>
</div>