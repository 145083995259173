import { Component, DoCheck, ElementRef, inject, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, OnInit, signal, ViewChild } from '@angular/core';

import { FieldType } from '@ngx-formly/material';
import { Observable, of } from 'rxjs';
import { FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { startWith, switchMap } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';

interface ExtraProps {
  filter: (term: string) => Observable<{ value: any; label: string; }[]>;
  displayFn: (value: any) => string;
}

@Component({
  selector: 'app-ngx-formly-material-chips',
  templateUrl: './ngx-formly-material-chips.component.html',
  styleUrls: ['./ngx-formly-material-chips.component.scss'],
})
export class FormlyMatChipsComponent extends FieldType<FieldTypeConfig<FormlyFieldProps & ExtraProps>> implements OnInit, DoCheck {
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  filter?: Observable<{value: any; label: string;}[]>;
  searchFormControl = new FormControl<string>('');

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  // readonly selectedValues: any[] = [];
  
  displayFn?: (value: any) => string;
  private toDiffer?: KeyValueDiffer<string, any>;

  announcer = inject(LiveAnnouncer);
  
  constructor(private keyValueDiffers: KeyValueDiffers) {
    super();
  }
  
  ngOnInit(): void {
    this.filter = this.searchFormControl.valueChanges.pipe(
      startWith(''),
      switchMap((term) => {
        if (!this.props['filter']){
          return of([]);
        }
        return this.props['filter'](term || '') as Observable<{ value: any; label: string; }[]>;
      }),
    );
    try {
      this.toDiffer = this.keyValueDiffers.find(this.props || {}).create();
    } catch (error) {
      console.error(error);
    }
  }

  displayValue(value: any): string {
    return this.displayFn?.(value) || this.defaultDisplayFn(value);
  }

  defaultDisplayFn(value: any): string {
    return value?.label || value || '';
  }

  toChanged(changes: KeyValueChanges<string, any>) {
    /* If you want to see details then use
      changes.forEachRemovedItem((record) => ...);
      changes.forEachAddedItem((record) => ...);
      changes.forEachChangedItem((record) => ...);
    */
    this.displayFn = this.props['displayFn'];
  }

  ngDoCheck(): void {
    const changes = this.toDiffer?.diff(this.props);
    if (changes) {
      this.toChanged(changes);
    }
  }

  add(event: MatChipInputEvent): void {
    console.log('add event', event);
    const value = (event.value || '').trim();
    if (value) {
      const controlValue = this.formControl.value;
      controlValue?.push(value);
      this.formControl.setValue(controlValue || [value]);
    }
    // Clear the input value
    event.chipInput!.clear();
    this.searchFormControl.setValue(null);
  }

  remove(value: any): void {
    console.log('remove value', value);
    const index = this.formControl.value.indexOf(value);
    if (index >= 0) {
      const controlValue = this.formControl.value;
      controlValue?.splice(index, 1);
      this.formControl.setValue(controlValue || [value]);

      this.announcer.announce(`Removed ${value}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const controlValue = this.formControl.value;
    controlValue?.push(event.option.value);
    this.formControl.setValue(controlValue || [event.option.value]);
    this.searchInput.nativeElement.value = '';
    this.searchFormControl.setValue(null);
  }
}
