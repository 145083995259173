import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FileTypeConfig, FILE_TYPE_CONFIG } from '../file-type-config';

@Component({
  selector: 'ngx-formly-material-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  // @ViewChild('fileInput', { static: true })
  // fileInput?: ElementRef;
  fileInput: HTMLInputElement;

  @ViewChild('dropzone', { static: true })
  set dropzone(el : ElementRef) {
    el.nativeElement.addEventListener('dragenter', this.addDragoverClass.bind(this), false);
    el.nativeElement.addEventListener('dragover', this.addDragoverClass.bind(this), false);
    el.nativeElement.addEventListener('drop', this.onDrop.bind(this), false);
    el.nativeElement.addEventListener('dragleave', this.removeDragoverClass.bind(this), false);
    el.nativeElement.addEventListener('dragend', this.removeDragoverClass.bind(this), false);
  }

  @Input() disabled?: boolean;

  @Output()
  selectFile = new EventEmitter<File>();

  constructor(
    private readonly renderer: Renderer2,
    @Inject(FILE_TYPE_CONFIG) public readonly fileTypeConfig: FileTypeConfig,
  ) {
    this.fileInput = renderer.createElement('input');
    this.fileInput.type = 'file';
    this.fileInput.onchange = (event) => this.onChange(event);
    this.fileInput.hidden = true;
    document.getElementsByTagName('body')[0].append(this.fileInput);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.fileInput?.remove();
  }

  private addDragoverClass(event: any) {
    if (this.disabled) {
      return;
    }
    event.dataTransfer.dropEffect = 'copy';
    event.stopPropagation();
    event.preventDefault();
    this.renderer.addClass(this.dropzone.nativeElement, 'is-dragover');
  }

  private removeDragoverClass(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.renderer.removeClass(this.dropzone.nativeElement, 'is-dragover');
  }

  private onDrop(event: any) {
    if (this.disabled) {
      return;
    }
    this.removeDragoverClass(event);
    const fileList: FileList = event.dataTransfer.files;
    const item = fileList.item(0);
    if (item) {
      this.selectFile.emit(item);
    }
  }

  onBrowseFiles() {
    console.log('onBrowseFiles', this.fileInput);
    if (this.fileInput) {
      this.fileInput.value = '';
      this.fileInput.click();
    }
  }

  onChange(event: any) {
    this.selectFile.emit(event.target.files.item(0));
  }

}
