import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { GoogleMapsApiConfig, GOOGLE_MAPS_API_CONFIG } from './google-maps-api.config';

function _window(): any {
  return window;
}
@Injectable({
  providedIn: 'root'
})
export class GoogleMapsApiService {
  apiLoadedSubject: ReplaySubject<boolean> = new ReplaySubject(1);
  get apiLoaded(): Observable<boolean>{
    return this.apiLoadedSubject.asObservable();
  }

  google?: any;
  constructor(
    private httpClient: HttpClient,
    @Inject(GOOGLE_MAPS_API_CONFIG) public readonly googleMapsApiConfig: GoogleMapsApiConfig,
  ) {
    const window = _window();
    this.google = window ? window.google : null;
    if (this.google){
      this.apiLoadedSubject.next(true);
      return;
    }

    const apiKey = this.googleMapsApiConfig.apiKey;
    let url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    const libs = this.googleMapsApiConfig.libraries;
    if (libs && libs.length) {
      url += ('&libraries=' +
        libs.reduce((prev, curr) => (prev += (curr +  ',')), ''))
    }
    console.log('url', url);
    this.httpClient.jsonp(url, 'callback').subscribe(
        () => {
          const window = _window();
          this.google = window ? window.google : null;
          // console.log('Google maps api loaded', this.google);
          this.apiLoadedSubject.next(true)
        },
        error => {
          console.log('Google maps api load error', error);
          this.apiLoadedSubject.next(false);
          return of(false);
        }
      )
  }
}
