import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete'; 

import { GMPlacesComponent } from './gmplaces.component';
import { GMPlacesService } from './gmplaces.service';

@NgModule({
  declarations: [
    GMPlacesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
  ],
  exports: [
    GMPlacesComponent
  ]
})
export class GMPlacesModule {
  static forRoot(): ModuleWithProviders<GMPlacesModule> {
    return {
      ngModule: GMPlacesModule,
      providers: [
        GMPlacesService
      ]
    };
  }
}
