import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { NgxFormlyMaterialUploadedFile, NgxFormlyMaterialUploadOptions } from '../file-upload.service';

export function createFileValidator(required: boolean) {
  return (c: UntypedFormControl) => {
    // console.log('validator c')
    const err = {
      requiredError: {
        given: c.value,
      }
    };
    return required ? (c.value === undefined || c.value == null) ? err : null : null;
  };
}

@Component({
  selector: 'app-file-input-upload',
  templateUrl: './file-input-upload.component.html',
  styleUrls: ['./file-input-upload.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileInputUploadComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => FileInputUploadComponent), multi: true },
    { provide: MatFormFieldControl, useExisting: FileInputUploadComponent },
  ]

})
export class FileInputUploadComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, MatFormFieldControl<NgxFormlyMaterialUploadedFile> {  
  static nextId = 0;
  shouldLabelFloat: any;
  @HostListener('[id]') id;
  @HostListener('[attr.aria-describedby]') describedBy;

  private valuePrivate: NgxFormlyMaterialUploadedFile | null = null;
  @Input()
  get value(): NgxFormlyMaterialUploadedFile | null {
    return this.valuePrivate;
  }
  set value(value: NgxFormlyMaterialUploadedFile | null) {
    // console.log('set value', value);
    if (value !== this.value) {
      this.uploadedFile = !value ? undefined : value;
      this.valuePrivate = value;
      this.stateChanges.next();
    }
  }

  uploadedFile?: NgxFormlyMaterialUploadedFile = undefined;
  
  private disabledPriv = false;
  @Input()
  get disabled(): boolean { return this.disabledPriv; }
  set disabled(value: boolean) {
    this.disabledPriv = coerceBooleanProperty(value);
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  private requiredPriv = false;
  @Input()
  get required(): boolean { return this.requiredPriv; }
  set required(value: boolean) {
    this.requiredPriv = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input() placeholder: string = '';
  
  stateChanges = new Subject<void>();
  focused = false;
  ngControl = null;
  errorState = false;
  controlType = 'app-file-input-upload';

  propagateChange: (_: any) => void = (_: any) => { };
  propagateTouched: () => void = () => { };
  validateFn: any = () => { };

  get empty() {
    return !this.value;
  }

  @Input() uploadOptions?: NgxFormlyMaterialUploadOptions;
  selectedFile?: File;
  constructor(
    private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>,
  ) {
    fm.monitor(elRef, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.id = `app-file-input-upload-${FileInputUploadComponent.nextId++}`;
    this.describedBy = '';
  }

  private hideRemovePriv = false;
  @Input()
  get hideRemove(): boolean { return this.hideRemovePriv; }
  set hideRemove(value: boolean) {
    this.hideRemovePriv = coerceBooleanProperty(value);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('ngOnChanges', changes);
    if (changes) {
      this.validateFn = createFileValidator(this.required);
    }
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  writeValue(value: NgxFormlyMaterialUploadedFile) {
    // console.log('writeValue', value, typeof value );
    if (value) {
      this.value = value;
    } else {
      this.value = null;
    }
  }
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  selectFile(file: File) {
    this.propagateTouched();
    this.selectedFile = file;
  }

  deleteFile() {
    this.selectedFile = undefined;
    this.uploadedFile = undefined;
    this.propagateChange(null);
    this.propagateTouched();
  }


  onUploadedFile(file: any) {
    // console.log('uploadedFile', file);
    this.selectedFile = undefined;
    this.uploadedFile = file;
    this.propagateChange(file);
  }

}
