import { Component } from '@angular/core';

@Component({
  selector: 'lib-tauns-common',
  template: `
    <p>
      tauns-common works!
    </p>
  `,
  styles: [
  ]
})
export class TaunsCommonComponent {

}
