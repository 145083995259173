import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { map, catchError } from 'rxjs/operators';
import { FetchResult } from '@apollo/client/core';
import { ApolloErrorHandlerService, HandleError, ApolloErrorHandlerType } from './apollo-error-handler.service';
import { RequestMapFn, ResponseMapFn, NgxFormlyMaterialUploadedFile } from '../components/ngx-formly-material-file/file-upload.service';
import { HttpRequest, HttpResponse } from '@angular/common/http';

export interface GetSignedUploadResponse {
  signedUpload: {
    url: string;
  };
}

const GetSignedUpload = gql`
  query GetSignedUploadAdmin($fileName: String!, $fileType: String!, $folder: String!) {
    signedUpload(fileName: $fileName, fileType: $fileType, folder: $folder) {
      url
    }
  }`;

export interface SignedUploadArgs {
    fileName: string;
    fileType: string;
    folder?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private handleError: HandleError;

  constructor(
    private apollo: Apollo,
    private apolloErrorHandlerService: ApolloErrorHandlerService,
  ) {
    this.handleError = this.apolloErrorHandlerService.createHandleError('FileUploadService');
  }

  getMaps(folder: string, paramName: string): { fileRequestMap: RequestMapFn, fileResponseMap: ResponseMapFn} {
    const fileRequestMap = (request: HttpRequest<FormData>): Observable<HttpRequest<File>> => {
      // console.log('fileRequestMap', request.body);
      if (!request.body) {
        throw new Error('No body');
      }
      const file: File = (request.body.get(paramName) as File);
      const signedUploadArgs: SignedUploadArgs = {
        fileName: file.name,
        fileType: file.type,
        folder
      };
      let newRequest: HttpRequest<File> = request.clone({ body: file });
      return this.signFileUpload(signedUploadArgs).pipe(
        map((url: string) => {
          // console.log('signFileUpload url', url);
          let headers = newRequest.headers;
          headers = headers.set('Content-type', file.type);
          headers = headers.set('x-amz-acl', 'public-read');
          newRequest = newRequest.clone({ headers });
          return newRequest.clone({ url });
        })
      );
    };

    const fileResponseMap = (event: HttpResponse<any>, request: HttpRequest<any>) => {
      // console.log('responseMap', event, request);
      const urlParts = request.url.split('?');
      const url = urlParts ? urlParts[0] ? urlParts[0] : request.url : request.url;
      // console.log('url', url);
      const key = url.split('.com/')[1];
      // console.log('key', key);
      const regex = new RegExp(`/(${folder}\/).*?-/`);
      const originalFilename = key.replace(regex, '');
      return {
        name: originalFilename,
        type: request.body.type,
        size: request.body.size,
        url,
        cloudId: key,
      } as NgxFormlyMaterialUploadedFile;
    };
    return { fileRequestMap, fileResponseMap}
  }

  signFileUpload(args: SignedUploadArgs): Observable<string> {
    // console.log('signFileUpload', args);
    return this.apollo.query({
      query: GetSignedUpload,
      variables: args
    }).pipe(
      map((res: FetchResult<unknown>) => {
        // console.log('signFileUpload res', res);
        return (res.data as GetSignedUploadResponse).signedUpload.url;
      }),
      catchError(this.handleError<string>('signFileUpload', undefined, ApolloErrorHandlerType.DISCREET))
    );
  }
}
