import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { cloneDeep, isEqual } from 'lodash';

export interface SubItemDialogData {
  fields: FormlyFieldConfig[];
  options?: FormlyFormOptions;
  item?: any;
  disabled?: boolean;
  readonly?: boolean;
}

@Component({
  selector: 'app-sub-item-dialog',
  templateUrl: './sub-item-dialog.component.html',
  styleUrls: ['./sub-item-dialog.component.scss']
})
export class SubItemDialogComponent implements OnInit {
  item: any;
  
  form = new UntypedFormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions;

  changed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SubItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SubItemDialogData,
  ) {
    this.fields = data.fields;
    this.options = data.options ? data.options  : {};
    if (data.item) {
      this.model = data.item;
      this.item = cloneDeep(data.item);
    }
    if (data.disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  ngOnInit(): void {
  }

  modelChange(event: any) {
    this.changed = !isEqual(this.item, event);
  }

  close() {
    this.dialogRef.close();
  }

  submitForm() {
    console.log('onSubmitForm', this.model, this.item, this.form.value);
    this.dialogRef.close(this.form.value);
  }

}
