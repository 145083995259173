import { Address } from './Address';
import { MapLocationDocument } from './MapLocation';

export enum ResidentRole {
    ADMINISTRATOR = 'ADMINISTRATOR',
    ADVANCED = 'ADVANCED',
    LIMITED = 'LIMITED',
}

export enum ResidentStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE'
}

export interface ResidentItem {
    _id: string;
    shortId: string;
    status: ResidentStatus;
    createdAt: Date;
    role?: ResidentRole;
    name: string;
    grouped?: number;
}

export interface ResidentGroup {
    _id: string;
    admin?: ResidentItem;
    group?: {
        role: ResidentRole;
        residentId: string;
    }[];
    groupPopulated?: {
        role: ResidentRole;
        resident: ResidentItem;
    }[];
    address: Address;
    mapLocation: MapLocationDocument;
    defaulter: boolean;
}
